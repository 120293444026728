import React, { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../../assets/icons/rawdata/arrow.svg";
import { ReactComponent as Edit } from "../../assets/icons/rawdata/akar-icons_edit.svg";
import { Button, Col, Divider, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { runCalculations } from "../../features/RawData/RawDataSlice";
import { ca } from "date-fns/locale";


const VoyageData = ({ selectedOption, record }) => {
  const dispatch = useDispatch();
  const { calculationsStatus, calculationsData } = useSelector((state) => state.rawData);

  const [isResultSynced, setIsResultSynced] = useState(record?.casper_output.is_result_synced);

  // maintaining flag so that the calculations's api data could be displayed on successful response
  let rerunApiFlag = false;

  const calculationsPayload = {
    casper_id: record?.casper_id,
  };

  // Rerun calculations api call when the rerun button is clicked
  const handleRerunClick = async () => {
    await dispatch(runCalculations(calculationsPayload));
    if (calculationsStatus !== 'failed') {
      rerunApiFlag = true;
      setIsResultSynced(true);
    } else {
      console.log("Api call failed")
    }
  };

  return (
    <div className="voyage-data-container">
      {selectedOption === "Fuel Consumption" ? (
        <div className="voyage-data-multi" style={{ width: "25vw" }}>
          <div style={{ marginLeft: "-20px" }}>Standard Fuels</div>
          <Row
            style={{ marginLeft: "-19px" }}
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {record?.fuel_details.map((fuel, index) => (
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <div
                  className="voyage-data-header"
                  style={{
                    background: "white",
                    marginLeft: "-1rem",
                    fontSize: "10.5px",
                    fontWeight: "400",
                    lineHeight: "11.4px",
                  }}
                >
                  {fuel.name}
                </div>
                <div
                  className="voyage-data-content"
                  style={{ background: "white" }}
                >
                  {`${fuel.total_fuel} mt`}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div
          className="voyage-data-multi"
          style={{ width: "100%", position: "relative" }}
        >
          {isResultSynced === false ? (
            <>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "1rem", marginTop: "3rem", }}>
                <p>
                  Your casper results are not synced. Please rerun calculations.
                </p>
                <div onClick={handleRerunClick} className="reset-btn">
                  Rerun Calculation
                </div>
              </div>
            </>
          ) : (
            <Row
              style={{
                marginLeft: "-13px",
                lineHeight: "15.4px",
                marginBottom: "12px",
              }}
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    CO<sub>2</sub> Eq Emissions
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.co2eq_emissions.toFixed(3)}
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    Eeoi
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.eeoi.toFixed(3)}
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    CO<sub>2</sub> Eq Emissions Ets
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.co2eq_emissions_ets.toFixed(3)}
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    Identifier
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.identifier}
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    AER Ratio
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.cii.toFixed(3)}
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    CII
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{
                      background: "#71BD7E",
                      marginLeft: "-1rem",
                      padding: "4px 9px",
                      width: "24px",
                    }}
                  >
                    {record?.casper_output.technical_grade}
                  </div>
                </>
              </Col>
            </Row>
          )}
        </div>
      )}

      {selectedOption === "Fuel Consumption" ? (
        <>
          <hr></hr>
          <div className="voyage-data-multi" style={{ width: "18vw" }}>
            Alternate Fuels
            <Row
              style={{ marginLeft: "1px" }}
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              {record?.fuel_details.map(
                (fuel, index) =>
                  fuel.fuel_type === "ALTERNATE" && (
                    <Col
                      className="gutter-row"
                      style={{ marginTop: "12px" }}
                      span={12}
                    >
                      <div
                        className="voyage-data-header"
                        style={{
                          background: "white",
                          marginLeft: "-1rem",
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "11.4px",
                        }}
                      >
                        {fuel.name}
                      </div>
                      <div
                        className="voyage-data-content"
                        style={{ background: "white" }}
                      >
                        {`${fuel.total_fuel} mt`}
                      </div>
                    </Col>
                  )
              )}
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default VoyageData;
