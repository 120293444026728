import { Divider, Button } from 'antd';
import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { putCasperId, runCalculations } from '../../../features/RawData/RawDataSlice';
const StepperFooter = ({ onStepChange, currentStep, selectedCasperId, setSelectedCasperId, selectedCards, setShowStepperCard, setSelectedCards }) => {
    const dispatch = useDispatch();

    const handleNext = async () => {
        // console.log(currentStep)
        switch (currentStep) {
            case 0:
                onStepChange(1);
                break;
            case 1:
                // console.log(selectedCasperId)

                if (selectedCasperId !== '') {
                    // Create payload for putCasperId
                    const putCasperIdPayload = {
                        unique_id: selectedCards,
                        casper_id: selectedCasperId
                    };

                    try {
                        // Dispatch putCasperId and wait for it to complete
                        await dispatch(putCasperId(putCasperIdPayload));

                        // After putCasperId is completed, create payload for runCalculations
                        const calculationsPayload = {
                            // imo_number: data.imo_number_id,
                            casper_id: selectedCasperId,
                        };

                        // Dispatch runCalculations
                        await dispatch(runCalculations(calculationsPayload));

                        // Move to the next step
                        onStepChange(2);
                    } catch (error) {
                        // Handle any errors that occurred during dispatches
                        console.error('Error occurred:', error);
                    }
                } else {
                    alert("Select Casper Id");
                }
                break;
            case 2:
                setSelectedCasperId('');
                onStepChange(0);
                setSelectedCards([]);
                setShowStepperCard(false)

                break;
            default:
                onStepChange(1);
        }
    };
    const handleBack = () => {
        // console.log(currentStep)
        switch (currentStep) {
            case 0:
                setSelectedCasperId('');
                onStepChange(0);
                break;
            case 1:
                setSelectedCasperId('');
                onStepChange(0);
                break;
            case 2:
                setSelectedCasperId('');
                onStepChange(1);
                break;
            default:
                onStepChange(1);
        }
    };


    return (
        <div className='stepper-footer'>
            <Divider />

            <div className='stepper-footer-buttons'>
                <Button className='stepper-back-button' type="primary" icon={<LeftOutlined />} onClick={handleBack}> Back</Button>
                <Button onClick={handleNext} className='stepper-next-button' type="primary" >{currentStep === 2 ? 'Save & End' : "Save & Next"}<RightOutlined style={{ marginLeft: 8 }} /></Button>
            </div>
        </div>
    );
};

export default StepperFooter;
