import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";
import { hostname } from "../../data";

// Thunk for fetching vessel data
export const fetchVesselData = createAsyncThunk(
  'vesselsAnalysis/fetchVesselData',
  async (params, { rejectWithValue }) => {
    const {
      imo_number = '',
      requested_page = 1,
      shipowner_name = '',
      vessel_name = ''
    } = params;

    try {
      const request = await makeRequest(
        "get",
        `${hostname}api/ems/fetch_vessel_data/?imo_number=${imo_number}&requested_page=${requested_page}&ship_owner=${shipowner_name}&vessel_name=${vessel_name}`
      );
      return request.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

// Thunk for fetching ship owner suggestions
export const fetchShipOwners = createAsyncThunk(
  'vesselsAnalysis/fetchShipOwners',
  async (doc) => {
    const request = await makeRequest("get", `${hostname}api/ems/fetch_shipowner_suggestions/?doc=${doc}`);
    return request.data.data;
  }
);

// Thunk for fetching vessel suggestions
export const fetchVessels = createAsyncThunk(
  'vesselsAnalysis/fetchVessels',
  async (shipowner_name) => {
    const request = await makeRequest("get", `${hostname}api/ems/fetch_vessel_suggestions/?shipowner_name=${shipowner_name}`);
    return request.data.data;
  }
);

// Thunk for fetching document suggestions
export const fetchDocSuggestions = createAsyncThunk(
  'vesselsAnalysis/fetchDocSuggestions',
  async () => {
    const request = await makeRequest("get", `${hostname}api/ems/get_doc_suggestion/`);
    return request.data.data;
  }
);

// Thunk for updating p-wind and p-prop
export const updatePwindPprop = createAsyncThunk(
  'vesselsAnalysis/updatePwindPprop',
  async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("Authorization");
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `${token}`);


      const request = await fetch(`${hostname}api/ems/update_pwind_ppop/`, {
        method: "PATCH",
        headers: myHeaders,
        body: JSON.stringify(payload[0]),
      });
      if (!request.ok) {
        throw new Error(`HTTP error! status: ${request.status}`);
      }
      const data = await request.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

// Create slice


const VesselsAnalysisSlice = createSlice({
  name: 'vesselsAnalysis',
  initialState: {
    status: 'idle',
    vessels: [],
    page: 1,
    total_pages: 0,
    fetchVesselDataStatus: 'idle',
    fetchVesselDataError: null,
    shipOwners: [],
    shipOwnersStatus: 'idle',
    vesselDetails: [],
    vesselStatus: 'idle',
    docSuggestions: [],
    docSuggestionsStatus: 'idle',
    updatePwindPpropStatus: 'idle',
    updatePwindPpropError: null,
  },
  reducers: {
    clearVessels: (state) => {
      state.page = 1;
      state.vessels = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetch vessel data
      .addCase(fetchVesselData.pending, (state) => {
        state.fetchVesselDataStatus = 'loading';
      })
      .addCase(fetchVesselData.fulfilled, (state, action) => {
        state.fetchVesselDataStatus = 'succeeded';
        state.vessels = action.payload.data || [];
        state.page = action.payload.page || 1;
        state.total_pages = action.payload.total_pages || 0;
      })
      .addCase(fetchVesselData.rejected, (state, action) => {
        state.fetchVesselDataStatus = 'failed';
        state.fetchVesselDataError = action.payload;
      })
      // Handle update p-wind and p-prop
      .addCase(updatePwindPprop.pending, (state) => {
        state.updatePwindPpropStatus = 'loading';
      })
      .addCase(updatePwindPprop.fulfilled, (state) => {
        state.updatePwindPpropStatus = 'succeeded';
      })
      .addCase(updatePwindPprop.rejected, (state, action) => {
        state.updatePwindPpropStatus = 'failed';
        state.updatePwindPpropError = action.payload;
      })
      // Handle other thunks similarly
      .addCase(fetchShipOwners.pending, (state) => {
        state.shipOwnersStatus = 'loading';
      })
      .addCase(fetchShipOwners.fulfilled, (state, action) => {
        state.shipOwnersStatus = 'succeeded';
        state.shipOwners = action.payload;
      })
      .addCase(fetchShipOwners.rejected, (state) => {
        state.shipOwnersStatus = 'failed';
      })
      .addCase(fetchVessels.pending, (state) => {
        state.vesselStatus = 'loading';
      })
      .addCase(fetchVessels.fulfilled, (state, action) => {
        state.vesselStatus = 'succeeded';
        state.vesselDetails = action.payload;
      })
      .addCase(fetchVessels.rejected, (state) => {
        state.vesselStatus = 'failed';
      })
      .addCase(fetchDocSuggestions.pending, (state) => {
        state.docSuggestionsStatus = 'loading';
      })
      .addCase(fetchDocSuggestions.fulfilled, (state, action) => {
        state.docSuggestionsStatus = 'succeeded';
        state.docSuggestions = action.payload;
      })
      .addCase(fetchDocSuggestions.rejected, (state) => {
        state.docSuggestionsStatus = 'failed';
      });
  },
});

export const { clearVessels } = VesselsAnalysisSlice.actions;
export default VesselsAnalysisSlice.reducer;
