import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Skeleton } from 'antd'
import { Input, Button, Divider } from "antd";
import { fetchRawData } from '../../../features/RawData/RawDataSlice';
import DataRecordsHeader from './DataRecordsHeader'
import DataRecordsTable from './DataRecordsTable'

const { RangePicker } = DatePicker
const { Search } = Input;


const DataRecordsContainer = ({ onAssignCasperID, selectedCards, setSelectedCards, selectedCasperIdInFilters, selectedUniqueIdAuditTrace, setSelectedUniqueIdAuditTrace }) => {

  const dispatch = useDispatch()
  // useEffect(() => {
  //   const params = {
  //     content: "VOYAGE_DATA",
  //     unique_id: ''
  //   }
  //   dispatch(fetchRawData(params))
  // }, [])

  const handleCardSelect = (uniqueId) => {
    if (selectedCards.includes(uniqueId)) {
      setSelectedCards(selectedCards.filter(id => id !== uniqueId));
    } else {
      setSelectedCards([...selectedCards, uniqueId]);
    }
  };
  const resetSelection = () => {
    setSelectedCards([]);
  };

  return (
    <div className='card'>


      <DataRecordsHeader selectedCount={selectedCards.length} resetSelection={resetSelection} onAssignCasperID={onAssignCasperID} selectedCasperIdInFilters={selectedCasperIdInFilters} />

      <Divider />

      <DataRecordsTable selectedCards={selectedCards} handleCardSelect={handleCardSelect} selectedUniqueIdAuditTrace={selectedUniqueIdAuditTrace} setSelectedUniqueIdAuditTrace={setSelectedUniqueIdAuditTrace} />
    </div>
  )
}

export default DataRecordsContainer
