import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { ReactComponent as Edit } from "../../assets/icons/rawdata/akar-icons_edit.svg";

const initialClassConditions = [
  {
    title: "LSMGO",
    key: "lsmgo",
    value: 231,
    unit: "mt.",
    editMenu: false,
  },
  {
    title: "HSFO",
    key: "hsfo",
    value: 231,
    unit: "mt.",
    editMenu: false,
  },
  {
    title: "MDO",
    key: "mdo",
    value: 231,
    unit: "mt.",
    editMenu: false,
  },
  {
    title: "VLSO",
    key: "vlso",
    value: 231,
    unit: "mt.",
    editMenu: false,
  },
  {
    title: "ULSFO",
    key: "ulsfo",
    value: 231,
    unit: "mt.",
    editMenu: false,
  },
  {
    title: "LNG - Auxiliary",
    key: "lng_aux",
    value: 231,
    unit: "mt.",
    editMenu: false,
  },
  {
    title: "LNG - Main",
    key: "lng_main",
    value: 231,
    unit: "mt.",
    editMenu: false,
  },
  {
    title: "LNG - Others",
    key: "lng_others",
    value: 231,
    unit: "mt.",
    editMenu: false,
  },
  {
    title: "LNG - Boilers",
    key: "lng_boilers",
    value: 231,
    unit: "mt.",
    editMenu: false,
  },
  {
    title: "LPG - Main",
    key: "lpg_main",
    value: 231,
    unit: "mt.",
    editMenu: false,
  },
  {
    title: "LPG - Boilers",
    key: "lpg_boilers",
    value: 3.1,
    unit: "mt.",
    editMenu: false,
  },
];

const IceClass = ({ modal, setModal }) => {
  const [classConditions, setClassConditions] = useState(initialClassConditions);
  const [editValue, setEditValue] = useState(null);

  const handleCancel = () => {
    setModal(false);
  };

  const handleOk = () => {
    setModal(false);
  };

  const handleEdit = (key, value) => {
    setEditValue({ key, value });
    setClassConditions((prevConditions) =>
      prevConditions.map((condition) =>
        condition.key === key ? { ...condition, editMenu: true } : condition
      )
    );
  };

  const handleSave = (key) => {
    setClassConditions((prevConditions) =>
      prevConditions.map((condition) =>
        condition.key === key
          ? { ...condition, value: editValue.value, editMenu: false }
          : condition
      )
    );
    setEditValue(null);
  };

  const handleChange = (e) => {
    setEditValue({ ...editValue, value: e.target.value });
  };

  return (
    <div>
      <Modal
        title="Modify Ice Class Conditions"
        open={modal}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <div className='class-conditions-container'>
          <div className='class-conditions'>
            {classConditions.map((condition) => (
              <div className='class-condition' key={condition.key}>
                <div className='class-condition-header'>
                  {condition.title}
                </div>
                <div className='class-condition-content'>
                  {condition.editMenu ? (
                    <>
                      <Input
                        type='number'
                        defaultValue={condition.value}
                        onChange={handleChange}
                      />
                      <div
                        onClick={() => handleSave(condition.key)}
                        className='save-btn'
                      >
                        Save
                      </div>
                    </>
                  ) : (
                    <>
                      <span>{condition.value} {condition.unit}</span>
                      <div className='class-condition-content-icon'>
                        <Edit onClick={() => handleEdit(condition.key, condition.value)} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className='distance-container'>
            Distance
            <div className='distance-box'>
              23234 nm.
            </div>
          </div>

          <div className='modal-btns'>
            <div className='cancel-btn' onClick={handleCancel}>Cancel</div>
            <div className='submit-btn' onClick={handleOk}>Submit</div>
            <div></div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IceClass;
