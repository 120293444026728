import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CloseOutlined, LockOutlined } from '@ant-design/icons';
import Results from '../../../components/Modals/Results'; // Adjust the path as per your project structure
import { DatePicker, Skeleton } from 'antd'
import { Button } from "antd";
import { ReactComponent as PlayOutlineIcon } from '../../../assets/icons/PlayOutlineIcon.svg';
import biCalculator from '../../../assets/icons/rawdata/bi_calculator.png';
import { getCalculationsResults } from '../../../features/RawData/RawDataSlice';

const { RangePicker } = DatePicker;

const DataRecordsHeader = ({ selectedCount, resetSelection, onAssignCasperID, selectedCasperIdInFilters }) => {
  // console.log(selectedCasperIdInFilters)
  const dispatch = useDispatch()
  const { status, rawData, cardUniqueIDDetails } = useSelector((state) => state.rawData);

  const [customDate, setCustomDate] = useState(null);
  const [resultsVisible, setResultsVisible] = useState(false);

  const dateChangeHandler = (date) => {
    console.log(date);
    setCustomDate(date);
  };

  const simulateHandler = () => {
    dispatch(getCalculationsResults(selectedCasperIdInFilters));
    setResultsVisible(true);
  };

  const closeModal = () => {
    setResultsVisible(false);
  };

  return (
    <div className="vessel-card-filter">
      <div style={{ width: "40%" }}>
        <span className='Sattlement-Title'>Data Records</span>
        <p className='rawData-count'>{cardUniqueIDDetails.length} Records Found</p>

      </div>

      <div className='rawData-buttons'>
        {selectedCount > 0 && <p className='rawData-selected-count'>{selectedCount} records selected <CloseOutlined onClick={resetSelection} /></p>}
        <RangePicker format="YYYY-MM-DD" value={customDate} style={{ width: 250 }} onChange={dateChangeHandler} />
        <Button className='rawData-button' type="primary" style={{ backgroundColor: selectedCount > 0 ? '#678DFF' : '#C3C3C3', borderColor: selectedCount > 0 ? '#678DFF' : '#C3C3C3' }}
          disabled={selectedCount === 0} onClick={onAssignCasperID}>Assign Casper ID</Button>
        {selectedCasperIdInFilters && <>

          <Button
            onClick={() => simulateHandler()}
            style={{ display: 'flex', alignItems: 'center', color: "#005BF3", background: "#005BF326", borderRadius: '25px' }}
            icon={<img src={biCalculator} alt="biCalculator" style={{ width: '16px', height: '16px' }} />}
          >
            Results
          </Button>
          <Button onClick={() => simulateHandler()} style={{ display: 'flex', alignItems: 'center' }} icon={<PlayOutlineIcon style={{ width: '16px', height: '16px' }} />}>Run Calculation</Button>
          <Button icon={<LockOutlined />}>Lock</Button>
        </>
        }
      </div>

      <Results visible={resultsVisible} onClose={closeModal} />
    </div>
  );
};

export default DataRecordsHeader;
