import React, { useEffect, useState, useRef } from "react";
import { Select, Spin, Alert } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchCasperID, fetchShipOwners, fetchVessels, fetchRawData } from "../features/RawData/RawDataSlice";
import { fetchDocSuggestions } from '../features/VesselsAnalysis/VesselsAnalysisSlice'
import { fetchCasperRecords } from "../features/CasperAnalysis/CasperAnalysisSlice";
const { Option } = Select;

const euoptions = [
  { value: "noneu", label: "NonEU" },
  { value: "eu", label: "EU" },
];

const yearoptions = [{ value: "2024", label: "2024" }];

const RawDataFilters = ({ type, setSelectedCasperIdInFilters, iMONumber, setIMONumber, voyageYear, setVoyageYear }) => {
  const dispatch = useDispatch();
  const { shipOwners, shipOwnersStatus, vesselDetails, vesselStatus, casperID, casperIDStatus } = useSelector((state) => state.rawData);
  const { fetchCasperRecordsStatus, casperRecords, fetchCasperRecordError } = useSelector((state) => state.casperAnalysis);
  const [casperIDs, setCasperID] = useState(["Not Selected"]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shipOwner, setShipOwner] = useState("");
  const [docValue, setDOC] = useState("");
  const [vesselName, setVesselName] = useState("");
  // const [voyageYear, setVoyageYear] = useState(yearoptions[0].value);
  const [fromIdentifier, setFromIdentifier] = useState(euoptions[0].value);
  const [toIdentifier, setToIdentifier] = useState(euoptions[1].value);
  // const [iMONumber, setIMONumber] = useState("");
  const [casperId, setCasperId] = useState("9929821_2024_02");
  const [isResetDone, setResetDone] = useState(false);

  // console.log(shipOwners)

  const hasApplied = useRef(false);
  const { docSuggestions, docSuggestionsStatus } = useSelector((state) => state.vesselsAnalysis);
  useEffect(() => {
    if (vesselStatus === "succeeded" && vesselDetails.length > 0) {
      const defaultVessel = vesselDetails[0]; // Default to the first vessel
      setVesselName(defaultVessel.vessel_name);
      setIMONumber(defaultVessel.imo_number);
      // dispatch(fetchCasperID(defaultVessel.imo_number));
    }
  }, [dispatch, vesselDetails, vesselStatus]);

  useEffect(() => {
    if (
      shipOwnersStatus === "succeeded" &&
      shipOwners.length > 0 &&
      shipOwner === ""
    ) {
      const defaultShipOwner = shipOwners[0]; // Default to the first ship owner
      setShipOwner(defaultShipOwner);
      dispatch(fetchVessels(defaultShipOwner));
    }
  }, [dispatch, shipOwnersStatus, shipOwners, shipOwner]);

  useEffect(() => {
    if (
      docSuggestionsStatus === "succeeded" &&
      docSuggestions.length > 0 &&
      docValue === ""
    ) {
      const defaultDOC = docSuggestions[0]; // Default to the first ship owner
      setDOC(defaultDOC);
      dispatch(fetchShipOwners(defaultDOC));
    }
  }, [dispatch, docSuggestionsStatus, docSuggestions, docValue]);

  useEffect(() => {
    const savedFilters = localStorage.getItem("selectedFilters");
    if (savedFilters) {
      const {
        docValue,
        shipOwner,
        vesselName,
        voyageYear,
        fromIdentifier,
        toIdentifier,
        iMONumber,
        casperId,
      } = JSON.parse(savedFilters);

      setDOC(docValue);
      setShipOwner(shipOwner);
      setVesselName(vesselName);
      setVoyageYear(voyageYear);
      setFromIdentifier(fromIdentifier);
      setToIdentifier(toIdentifier);
      setIMONumber(iMONumber);
      setCasperId(casperId);

      // Fetch vessels based on the saved ship owner
      dispatch(fetchVessels(shipOwner));
    }
  }, [dispatch]);

  // useEffect(() => {
  //     if (
  //         shipOwner &&
  //         vesselName &&
  //         voyageYear &&
  //         fromIdentifier &&
  //         toIdentifier &&
  //         // iMONumber &&
  //         iMONumber

  //         // !hasApplied.current
  //     ) {
  //             handleApply();
  //             // hasApplied.current = true;

  //     }
  // }, [shipOwner, vesselName, voyageYear, fromIdentifier, toIdentifier, iMONumber]);

  useEffect(() => {
    if (voyageYear && fromIdentifier && toIdentifier) {
      handleApply();
    }
  }, [voyageYear, fromIdentifier, toIdentifier]);

  useEffect(() => {
    localStorage.removeItem("selectedFilters");
  }, [
    docValue,
    shipOwner,
    vesselName,
    voyageYear,
    fromIdentifier,
    toIdentifier,
    iMONumber,
    casperId,
    type,
  ]);

  useEffect(() => {
    // Remove filters on page refresh
    const handleBeforeUnload = () => {
      localStorage.removeItem("selectedFilters");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (vesselName) {
      const selectedVessel = vesselDetails.find(
        (vessel) => vessel.vessel_name === vesselName
      );
      if (selectedVessel) {
        setIMONumber(selectedVessel.imo_number);
        dispatch(fetchCasperID(selectedVessel.imo_number));
      }
    }
  }, [vesselName, vesselDetails, dispatch]);

  useEffect(() => {
    if (iMONumber) {
      const selectedVessel = vesselDetails.find(
        (vessel) => vessel.imo_number === iMONumber
      );
      if (selectedVessel) {
        setVesselName(selectedVessel.vessel_name);
        // dispatch(fetchCasperID(selectedVessel.imo_number));
      }
    }
  }, [iMONumber, vesselDetails, dispatch]);

  const handleShipOwnerChange = (value) => {
    dispatch(fetchVessels(value));
    setCasperID(["Not Selected"]);
    setSelectedCasperIdInFilters("");
    setShipOwner(value);
    setVesselName(""); // Reset vessel name when ship owner changes
    setIMONumber(""); // Reset IMO number when ship owner changes
  };

  const handleDOCChange = (value) => {
    dispatch(fetchShipOwners(value));
    setDOC(value);
    setCasperID(["Not Selected"]);
    setSelectedCasperIdInFilters("");
    setShipOwner('');
    setVesselName(""); // Reset vessel name when ship owner changes
    setIMONumber(""); // Reset IMO number when ship owner changes
  };

  const handleVesselChange = (value) => {
    setVesselName(value);
  };

  const handleYearChange = (value) => {
    setVoyageYear(value);
  };

  const fromIdentifierChange = (value) => {
    setFromIdentifier(value);
  };

  const toIdentifierChange = (value) => {
    setToIdentifier(value);
  };

  const casperIdChange = (value) => {
    setCasperId(value);
  };

  const handleIMONumberChange = (value) => {
    setIMONumber(value);
  };

  const resetFilters = () => {
    localStorage.removeItem("selectedFilters");
    dispatch(fetchDocSuggestions())
    // dispatch(fetchShipOwners());
    setDOC('')
    setShipOwner("");
    setSelectedCasperIdInFilters("");
    setVesselName("");
    setVoyageYear(yearoptions[0].value);
    setFromIdentifier(euoptions[0].value);
    setToIdentifier(euoptions[1].value);
    setIMONumber("");
    setCasperId("");
    setIMONumber("");

    setResetDone(true);
  };

  useEffect(() => {
    resetFilters();
  }, [type]);

  useEffect(() => {
    if (isResetDone && type === "Raw Data" && docValue !== '' && shipOwner !== "" && vesselName !== "" && voyageYear === yearoptions[0].value && fromIdentifier === euoptions[0].value && toIdentifier === euoptions[1].value && iMONumber !== "" && casperId === "") {
      dispatch(
        fetchRawData({
          docValue,
          shipOwner,
          vesselName,
          year: voyageYear,
          from: fromIdentifier,
          to: toIdentifier,
          imoNumber: iMONumber,
          requestedData: "VOYAGE_DATA",
          casperID: '',
          uniqueID: "",
        })
      );
      setResetDone(false)
    }
    else if (isResetDone && type === "Casper Analysis" && docValue !== '' && shipOwner !== "" && vesselName !== "" && voyageYear === yearoptions[0].value && fromIdentifier === euoptions[0].value && toIdentifier === euoptions[1].value && iMONumber !== "" && casperId === "") {
      dispatch(
        fetchCasperRecords({
          casperID: '',
          imoNumber: iMONumber,
          year: voyageYear,
        })
      )
      setResetDone(false)
    }
  }, [isResetDone, docSuggestions, type, docValue, shipOwner, vesselName, voyageYear, fromIdentifier, toIdentifier, iMONumber, casperId]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    localStorage.removeItem("selectedFilters");
  }, [type]);

  const handleApply = () => {
    if (type === "Raw Data") {
      setSelectedCasperIdInFilters(casperId);
      const selectedFilters = {
        docValue,
        shipOwner,
        vesselName,
        voyageYear,
        fromIdentifier,
        toIdentifier,
        iMONumber,
        casperId,
      };

      // localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
      dispatch(
        fetchRawData({
          docValue,
          shipOwner,
          vesselName,
          year: voyageYear,
          from: fromIdentifier,
          to: toIdentifier,
          imoNumber: iMONumber,
          requestedData: "VOYAGE_DATA",
          casperID: casperId,
          uniqueID: "",
        })
      );
    } else if (type === "Casper Analysis") {
      dispatch(
        fetchCasperRecords({
          casperID: casperId,
          imoNumber: iMONumber,
          year: voyageYear,
        })
      );
    }
  };

  return (
    <div
      className="raw-data-filters"
      style={{ width: type === "Raw Data" ? "98%" : "65vw" }}
    >
      {/* <div className="filter-title">Filters</div> */}
      <div className="filter-1-row">

        <div className="filters">
          <div className="filter-title">DOC</div>
          {docSuggestionsStatus === "loading" ? (
            <Spin />
          ) : docSuggestionsStatus === "failed" ? (
            <Alert
              message="Error"
              description="Failed to load DOC."
              type="error"
              showIcon
            />
          ) : (
            <Select
              showSearch
              value={docValue}
              style={{ width: 270 }}
              onChange={handleDOCChange}
            >
              {docSuggestions &&
                docSuggestions.map((doc) => (
                  <Option key={doc} value={doc}>
                    {doc}
                  </Option>
                ))}
            </Select>
          )}
        </div>

        <div className="filters">
          {/* <div className="filter-heading">Ship Owner</div> */}
          <div className="filter-title">Ship Owner</div>
          {shipOwnersStatus === "loading" ? (
            <Spin />
          ) : shipOwnersStatus === "failed" ? (
            <Alert
              message="Error"
              description="Failed to load ship owners."
              type="error"
              showIcon
            />
          ) : (
            <Select
              showSearch
              value={shipOwner}
              style={{ width: 270 }}
              onChange={handleShipOwnerChange}
            >
              {shipOwners &&
                shipOwners.map((owner) => (
                  <Option key={owner} value={owner}>
                    {owner}
                  </Option>
                ))}
            </Select>
          )}
        </div>
        <div className="filters">
          <div className="filter-title">Vessel Name</div>
          {vesselStatus === "loading" ? (
            <Spin />
          ) : vesselStatus === "failed" ? (
            <Alert
              message="Error"
              description="Failed to load vessels."
              type="error"
              showIcon
            />
          ) : (
            <Select
              showSearch
              value={vesselName}
              style={{ width: 270 }}
              onChange={handleVesselChange}
            >
              {vesselDetails &&
                vesselDetails.map((vessel) => (
                  <Option key={vessel.vessel_name} value={vessel.vessel_name}>
                    {vessel.vessel_name}
                  </Option>
                ))}
            </Select>
          )}
        </div>
        <div className="filters">
          <div className="filter-title">Voyage Year</div>
          <Select
            value={voyageYear}
            style={{ width: 270 }}
            onChange={handleYearChange}
          >
            {yearoptions.map((year) => (
              <Option key={year.value} value={year.value}>
                {year.label}
              </Option>
            ))}
          </Select>
        </div>

        {type === "Raw Data" && (
          <div className="filters">
            <div className="filter-title">EU Identifier</div>
            <div className="filters-eu">
              <div>
                <span className="filter-heading" style={{ marginRight: '5px' }}>From</span>
                <Select
                  showSearch
                  value={fromIdentifier}
                  style={{ width: 100 }}
                  onChange={fromIdentifierChange}
                >
                  {euoptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <span className="filter-heading" style={{ marginRight: '5px' }}>To</span>
                <Select
                  showSearch
                  value={toIdentifier}
                  style={{ width: 100 }}
                  onChange={toIdentifierChange}
                >
                  {euoptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>

              </div>
            </div>
          </div>
        )}
        <div className="filters">
          <div className="filter-title">IMO Number</div>
          <Select
            showSearch
            value={iMONumber}
            style={{ width: 270 }}
            onChange={handleIMONumberChange}
          >
            {vesselDetails &&
              vesselDetails.map((vessel) => (
                <Option key={vessel.imo_number} value={vessel.imo_number}>
                  {vessel.imo_number}
                </Option>
              ))}
          </Select>
        </div>
        <div className="filters">
          <div className="filter-title">Casper ID</div>
          <Select
            showSearch
            value={casperId}
            style={{ width: 270 }}
            onChange={casperIdChange}
          >
            {casperID.map((id) => (
              <Option key={id} value={id}>
                {id}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="buttons-container">
        <div
          style={{
            fontSize: "1.8vh",
            opacity: "0.7",
            fontStyle: "italic !important",
          }}
        >
          *Kindly select all applicable filters to view the corresponding
          records.
        </div>

        <div style={{ display: "flex", gap: "1vw" }}>
          <div className="reset-btn" onClick={resetFilters}>
            Reset
          </div>
          <div className="apply-btn" onClick={handleApply}>
            Apply
          </div>
        </div>
      </div>
    </div>
  )
}

export default RawDataFilters;
