// import userProfile from "../assets/images/sidebar/user-icon.png"
// import euaImg from "../assets/icons/sidebar/EUAImg.svg"
// import vesselImg from "../assets/icons/sidebar/VesselImg.svg"
// import voyageImg from "../assets/icons/sidebar/VoyageImg.svg"
// import notificationImg from "../assets/icons/sidebar/NotificationImg.svg"
// import newsRoomImg from "../assets/icons/sidebar/NewsRoomImg.svg"
// import efdbImg from "../assets/icons/sidebar/EFDBImg.svg"
// import frame from "../assets/icons/sidebar/Frame.svg"
// import logoutImg from "../assets/icons/sidebar/logout.svg"
// import { useNavigate } from "react-router"
// import { useSelector } from "react-redux"

// const Sidebar = () => {
//     const navigate = useNavigate()
//   const {user}= useSelector((state)=>state.user)

//     const SidebarData = [
//         {
//             title: "EUA",
//             image: euaImg,
//             link: "/"
//         },
//         {
//             title: "Vessels",
//             image: vesselImg,
//             link: "/vessels"
//         },
//         {
//             title: "Voyages",
//             image: voyageImg,
//             link: "/voyages"
//         },
//         {
//             title: "Notification",
//             image: notificationImg,
//             link: "/notifications",
//         },
//         {
//             title: "News Room",
//             image: newsRoomImg,
//             link: "/newsroom"
//         },
//         {
//             title: "EFDB",
//             image: efdbImg,
//             link: "/efdb"
//         },
//         {
//             title: "Logout",
//             image: logoutImg,
//             // link: ""
//         },
//     ]

//     const handleLogout = () => {
//         localStorage.removeItem('Authorization');
//         window.location.reload();
//     }
//     return (
//         <div className="sideBar" style={{ width: "239px" }}>

//             <div className="sideBar-userProfile">
//                 <div className="sideBar-userProfile-image">
//                     <img src={userProfile} alt="User Profile" />
//                 </div>
//                 <div className="sideBar-userProfile-text">
//                     <p>Hello,</p>
//                     <h1>{user?.username}</h1>
//                 </div>
//             </div>

//             <div className="sideBar-navigation">

//                 {
//                     SidebarData.map((navData, i) => (
//                         <div key={i} className="sideBar-navigation-elements" id={window.location.pathname === navData.link ? "active" : ""}>
//                             <div className="sideBar-elements" onClick={navData.title === "Logout" ? () => { handleLogout() } : () => { navigate(navData.link) }}>
//                                 <div className="sideBar-elements-Image">
//                                     <img src={navData.image} alt={navData.title} />
//                                 </div>
//                                 <div className="sideBar-elements-Text">
//                                     <p>{navData.title}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     ))
//                 }

//             </div>

//             <div className="sideBar-date">
//                 <div className="sideBar-date-elem1">
//                     <p>GMT</p>
//                 </div>
//                 <div className="sideBar-date-elem2">
//                     <h1>11:32:<sup>59</sup></h1>
//                 </div>
//                 <div className="sideBar-date-elem3">
//                     Monday, 9 October 2023
//                 </div>
//             </div>

//             <div className="sideBar-logo">
//                 <img src={frame} alt="Azolla" />
//             </div>
//         </div>
//     )
// }

// export default Sidebar


import React, { useState, useEffect } from "react";
import { sidebar_user_profile_icon_url } from "../data";
import euaImg from "../assets/icons/sidebar/EUAImg.svg";
import vesselImg from "../assets/icons/sidebar/VesselImg.svg";
import voyageImg from "../assets/icons/sidebar/VoyageImg.svg";
import notificationImg from "../assets/icons/sidebar/NotificationImg.svg";
import newsRoomImg from "../assets/icons/sidebar/NewsRoomImg.svg";
import efdbImg from "../assets/icons/sidebar/EFDBImg.svg";
import frame from "../assets/icons/sidebar/Frame.svg";
import logoutImg from "../assets/icons/sidebar/Logout.svg";
import rawdataImg from "../assets/icons/sidebar/RawData.svg"
import casperAnalysisImg from "../assets/icons/rawdata/casper-analysis.svg"
import vessalAnalysisImg from "../assets/icons/rawdata/tabler_ship.svg"
import opti from "../assets/icons/sidebar/Optimize.svg"
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";


const Sidebar = () => {
  const navigate = useNavigate()
  const SidebarData = [
    {
      title: "EUA",
      image: euaImg,
      link: "/"
    },
    {
      title: "Vessels",
      image: vesselImg,
      link: "/vessels"
    },
    {
      title: "Voyages",
      image: voyageImg,
      link: "/voyages"
    },
    {
      title: "Notification",
      image: notificationImg,
      link: "/notifications",
    },
    {
      title: "News Room",
      image: newsRoomImg,
      link: "/newsroom"
    },
    {
      title: "EFDB",
      image: efdbImg,
      link: "/efdb"
    },
    {
      title: "Voyage Optimization",
      image: opti,
      link: "/voyage-optimization"
    },
    {
      title: "Logout",
      image: logoutImg,
    },
  ]

  const SidebarDataEMS = [
    {
      title: "Raw Data",
      image: rawdataImg,
      link: "/"
    },
    {
      title: "Casper Analysis",
      image: casperAnalysisImg,
      link: "/casper-analysis"
    },
    {
      title: "Vessels-Analysis",
      image: vessalAnalysisImg,
      link: "/vessels-analysis"
    },
  ]
  const { user } = useSelector((state) => state.user);


  const [currentTime, setCurrentTime] = useState(new Date());

  const isPathMatrixTable = window.location.pathname === "/matrix-tables";

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const formattedTime = currentTime.toLocaleTimeString([], { timeZone: 'GMT', hour: '2-digit', minute: '2-digit', second: '2-digit' });

  const currentDate = new Date();
  const options = {
    timeZone: 'GMT',
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  };

  const formattedDate = currentDate.toLocaleDateString('en-US', options);


  // console.log(formattedDate);

  const handleLogout = () => {
    localStorage.removeItem("Authorization");
    localStorage.removeItem("user")
    window.location.href = '/'
    // window.location.reload();
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const handleNavigation = (navLink) => {
    console.log(user.role)
    console.log(navLink)
    if (user.role !== "Freemium") {
      navigate(navLink)
    }
  };

  useEffect(() => {
    setShowTooltip(user.role === "Freemium");
  }, [user.role]);

  // console.log(isPathMatrixTable)

  return (
    <div className={isPathMatrixTable ? "MatrixTableSideBar" : "sideBar"} style={{ width: "239px" }}>
      <div className="sideBar-userProfile">
        <div className={isPathMatrixTable ? "MatrixTableSideBardiv" : "sideBar-userProfile-image"}>
          <img className={isPathMatrixTable ? "MatrixTableSideBarImg" : ""} src={sidebar_user_profile_icon_url} alt="User Profile" />
        </div>
        {isPathMatrixTable ? '' : <div className="sideBar-userProfile-text">
          <p>Hello,</p>
          <h1>{user?.username}</h1>
        </div>}
      </div>

      {
        user.role === "ems" ? (<div className="sideBar-navigation-ems">
          <div>
            {SidebarDataEMS.map((navData, i) => (
              <div key={i} className={isPathMatrixTable ? "MatrixTableSideBarNavigationEle" : "sideBar-navigation-elements"} id={window.location.pathname === navData.link ? "active" : ""}>
                <div className="sideBar-elements" onClick={() => handleNavigation(navData.link)}>
                  <div className="sideBar-elements-Image">
                    {isPathMatrixTable ?
                      <Tooltip placement="right" title={navData.title}>
                        <img style={{ width: '20px' }} src={navData.image} alt={navData.title} />
                      </Tooltip>
                      :
                      <img style={{ width: '20px' }} src={navData.image} alt={navData.title} />}
                  </div>

                  {isPathMatrixTable ? '' :
                    <div className="sideBar-elements-Text">
                      {showTooltip && (
                        <Tooltip
                          title="The feature is exclusively accessible to basic plan users only."
                          color="#FF8585"
                        >
                          <p>{navData.title}</p>
                        </Tooltip>
                      )}
                      {!showTooltip && (

                        <p>{navData.title}</p>

                      )}
                    </div>}


                </div>
              </div>
            ))}
          </div>

          <div className="sideBar-logout" style={{display: "flex", cursor: "pointer"}}  onClick={() => handleLogout()}>
            <div className="sideBar-elements-Image">
              <Tooltip placement="right" title={"Logout"}>
                <img style={{ width: '20px' }} src={logoutImg} alt={"Logout"} />
              </Tooltip>
            </div>
            <div className="sideBar-elements-Text">
              <p>Logout</p>
            </div>
          </div>


        </div>) : (<div className="sideBar-navigation">
          {SidebarData.map((navData, i) => (
            <div key={i} className={isPathMatrixTable ? "MatrixTableSideBarNavigationEle" : "sideBar-navigation-elements"} id={window.location.pathname === navData.link ? "active" : ""}>
              <div className="sideBar-elements" onClick={navData.title === "Logout" ? () => { handleLogout() } : () => { handleNavigation(navData.link) }}>
                <div className="sideBar-elements-Image">
                  {isPathMatrixTable ?
                    <Tooltip placement="right" title={navData.title}>
                      <img style={{ width: '20px' }} src={navData.image} alt={navData.title} />
                    </Tooltip>
                    :
                    <img style={{ width: '20px' }} src={navData.image} alt={navData.title} />}
                </div>

                {isPathMatrixTable ? '' :
                  <div className="sideBar-elements-Text">
                    {showTooltip && navData.title !== "Logout" && (
                      <Tooltip
                        title="The feature is exclusively accessible to basic plan users only."
                        color="#FF8585"
                      >
                        <p>{navData.title}</p>
                      </Tooltip>
                    )}
                    {navData.title === "Logout" && (
                      <p>{navData.title}</p>
                    )}

                    {!showTooltip && navData.title !== "Logout" && (

                      <p>{navData.title}</p>

                    )}
                  </div>}


              </div>
            </div>
          ))}
        </div>)
      }



      {isPathMatrixTable ? '' : <div className="sideBar-date">
        <div className="sideBar-date-elem1">
          <p>GMT</p>
        </div>
        <div className="sideBar-date-elem2">
          <h1>{formattedTime}</h1>
        </div>
        <div className="sideBar-date-elem3">{formattedDate}</div>
      </div>}

      {isPathMatrixTable ? '' :
        <div className="sideBar-logo">
          <img src={frame} alt="Azolla" />
        </div>}
    </div>
  );
};

export default Sidebar;
