import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import Heading from './Heading';
import { FileImageOutlined } from '@ant-design/icons';
import report from "../../../assets/icons/rawdata/carbon_report.png";
import { useDispatch, useSelector } from 'react-redux';
import { Select, Button, Dropdown, Menu, Modal, Form, Input } from 'antd';
import EditIcon from '../../../assets/icons/voyages/edit.svg';
import { getPortNames, updateVoyageInfo, fetchRawData } from '../../../features/RawData/RawDataSlice';

const { Option } = Select;
const consumptionTypeOptions = ["Sea", "Port", "Anchor", "Bunkering", "Drifting", "Other"]
const StepperFirstBasicDetails = ({ selectedUniqueId, selectedCards }) => {
    const { status, rawData, portSuggestions } = useSelector((state) => state.rawData);
    // console.log(rawData?.[selectedUniqueId])
    const data = rawData?.[selectedUniqueId][0];

    const dispatch = useDispatch();
    const [editingField, setEditingField] = useState(null);
    const [editableData, setEditableData] = useState(rawData[selectedUniqueId][0]);
    const [searchValue, setSearchValue] = useState('');
    const [fromPortName, setFromPortName] = useState('');
    const [toPortName, setToPortName] = useState('');
    const [editedData, setEditedData] = useState({});
    const [hasChanges, setHasChanges] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    useEffect(() => {
        setEditableData(rawData[selectedUniqueId][0]);
        setFromPortName('')
        setToPortName('')
    }, [selectedUniqueId]);
    const handleDropdownChange = (field, value, originalValue) => {
        handleInputChange(field, value, originalValue);
    };

    const handleEditClick = (field) => {
        setEditingField(field);
    };

    const handleInputChange = (field, value, oldValue) => {
        const selectedPort = portSuggestions.find(port => port.port_code === value);

        setEditableData((prevState) => ({
            ...prevState,
            [field]: value,
            ...(field === 'from_port_code' && { from_port_name: selectedPort?.port_name }),
            ...(field === 'to_port_code' && { to_port_name: selectedPort?.port_name }),
        }));

        switch (field) {
            case 'from_port_code':
                setFromPortName(selectedPort?.port_name);
                handleEdit(field, value, oldValue, "from_port_name", selectedPort?.port_name, data?.from_port_name);
                break;
            case 'to_port_code':
                setToPortName(selectedPort?.port_name);
                handleEdit(field, value, oldValue, "to_port_name", selectedPort?.port_name, data?.to_port_name);
                break;
            // case 'consumption_type':
            //     // handleEdit(field, value, oldValue);
            //     break;
            default:

                handleEdit(field, value, oldValue);
                break;
        }
    };

    const handleBlur = () => {
        setEditingField(null);
    };
    // console.log(editableData)
    let details = []


    details = [
        { label: "Time Lapsed From Last Report Hrs.", value: !data?.time_lapsed_from_last_report_hrs ? '-' : data?.time_lapsed_from_last_report_hrs },
        { label: "Event", value: !data?.event ? '-' : data?.event, field: 'event' },
        { label: "From Port Code", value: !data?.from_port_code ? '-' : data?.from_port_code, field: 'from_port_code' },
        { label: "Vessel Name", value: "Not in response" },
        { label: "UTC", value: !data?.utc_date_time ? '-' : data?.utc_date_time, field: 'location' },
        { label: "From Port Name", value: fromPortName || !data?.from_port_name ? '-' : data?.from_port_name },
        { label: "Report Date", value: !data?.report_date ? '-' : data?.report_date.replace('T', ' T') || 'N/A' },
        { label: "Location", value: !data?.location ? '-' : data?.location },
        { label: "To Port Code", value: !data?.to_port_code ? '-' : data?.to_port_code, field: 'to_port_code' },
        { label: "To Port Name", value: toPortName || !data?.to_port_name ? '-' : data?.to_port_name },
        { label: 'Vessel Status', value: !data?.consumption_type ? '-' : data?.consumption_type || 'N/A', field: 'consumption_type' },
    ];


    useEffect(() => {
        details = [
            { label: "Time Lapsed From Last Report Hrs.", value: !data?.time_lapsed_from_last_report_hrs ? '-' : data?.time_lapsed_from_last_report_hrs },
            { label: "Event", value: !data?.event ? '-' : data?.event, field: 'event' },
            { label: "From Port Code", value: !data?.from_port_code ? '-' : data?.from_port_code, field: 'from_port_code' },
            { label: "Vessel Name", value: "Not in response" },
            { label: "UTC", value: !data?.utc_date_time ? '-' : data?.utc_date_time, field: 'location' },
            { label: "From Port Name", value: fromPortName },
            { label: "Report Date", value: !data?.report_date ? '-' : data?.report_date.replace('T', ' T') || 'N/A' },
            { label: "Location", value: !data?.location ? '-' : data?.location },
            { label: "To Port Code", value: !data?.to_port_code ? '-' : data?.to_port_code, field: 'to_port_code' },
            { label: "To Port Name", value: toPortName },
            { label: 'Vessel Status', value: !data?.consumption_type ? '-' : data?.consumption_type || 'N/A', field: 'consumption_type' },
        ];
    }, [fromPortName, toPortName]);

    const maxRowsPerColumn = 3;
    const columns = [];

    // Initialize columns based on data length
    for (let i = 0; i < Math.ceil(details.length / maxRowsPerColumn); i++) {
        columns.push(details.slice(i * maxRowsPerColumn, (i + 1) * maxRowsPerColumn));
    }

    // Ref to store maximum label width for each column
    const labelRefs = useRef([]);

    // Effect to measure label widths
    useLayoutEffect(() => {
        labelRefs.current = labelRefs.current.slice(0, columns.length); // ensure refs array is not longer than columns array
        columns.forEach((column, columnIndex) => {
            let maxWidth = 0;
            column.forEach((item, index) => {
                const labelWidth = labelRefs.current[columnIndex].children[index].children[0].offsetWidth;
                if (labelWidth > maxWidth) {
                    maxWidth = labelWidth;
                }
            });
            // Set the column width based on the maximum label width found
            //   labelRefs.current[columnIndex].style.minWidth = `${maxWidth}px`;
            labelRefs.current[columnIndex].style.minWidth = `100px`;
        });
    }, [columns]);


    const handleSearch = (value) => {
        const uppercasedValue = value.toUpperCase();
        setSearchValue(uppercasedValue);
        if (uppercasedValue.length >= 3) {
            dispatch(getPortNames(uppercasedValue));
        }
    };

    const handleKeyDown = (event) => {
        if (event.key.length === 1 && /^[a-zA-Z]$/.test(event.key)) {
            event.preventDefault();
            const uppercasedKey = event.key.toUpperCase();
            const newValue = searchValue + uppercasedKey;
            setSearchValue(newValue);
            if (newValue.length >= 3) {
                dispatch(getPortNames(newValue));
            }
        } else if (event.key === 'Backspace') {
            const newValue = searchValue.slice(0, -1);
            setSearchValue(newValue);
        }
    };

    const handleEdit = (field, value, oldValue, nameField, newName, oldName) => {
        // console.log(field)
        setEditedData({
            ...editedData,
            [field]: {
                initial_value: oldValue,
                final_value: value,
            },
            [nameField]: {
                initial_value: oldName,
                final_value: newName,
            },
        });
        setHasChanges(true);
    };

    const handleSave = () => {
        setIsModalVisible(true);
    };


    const handleModalOk = () => {
        form
            .validateFields()
            .then((values) => {
                // Handle form submission here
                // console.log('User Remark:', values.remark);
                // console.log(editedData)

                const params = {
                    ...editedData,
                    unique_id: "",
                    remark: values.remark,
                    unique_id: selectedUniqueId
                }

                console.log(params)

                dispatch(updateVoyageInfo(params))


                const paramsRawData = {
                    // shipOwner: dataType.shipOwner,
                    // vesselName: dataType.vesselName,
                    // year: data.utc_date_time.trim(0, 4),
                    // from: data.from_port_identifier.toLowerCase(),
                    // to: dataType.to_port_identifier.toLowerCase(),
                    // imoNumber: data.imo_number_id,
                    // requestedData: 'VOYAGE_DATA',
                    // casperID: data.casper_id || '',
                    // uniqueID: selectedUniqueId,
                }
                // dispatch(fetchRawData(paramsRawData))
                setIsModalVisible(false);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className='stepper-first-basic-details'>
            <Heading title="Basic Report Details" iconImg={report} uniqueId={selectedUniqueId} selectedCards={selectedCards} />
            <div style={{ display: 'flex', marginTop: '15px' }}>
                {columns.map((column, columnIndex) => (
                    <div key={columnIndex} ref={(el) => (labelRefs.current[columnIndex] = el)} style={{ display: 'flex', flexDirection: 'column', marginRight: '25px' }}>
                        {column.map((item, index) => (
                            <div key={index} style={{ marginBottom: '15px' }}>
                                <p className='data_labels'>
                                    {item.label} {item.field && (
                                        <img
                                            src={EditIcon}
                                            alt="Edit"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleEditClick(item.field)}
                                        />
                                    )}
                                </p>
                                {editingField === item.field ? (
                                    item.field === 'consumption_type' ? (
                                        <Select
                                            value={editableData[item.field]}
                                            onChange={(value) => handleInputChange(item.field, value, item.value)}
                                            onBlur={handleBlur}
                                            style={{ width: 130 }}
                                        >
                                            {consumptionTypeOptions.map((option) => (
                                                <Option key={option} value={option}>
                                                    {option}
                                                </Option>
                                            ))}
                                        </Select>
                                    ) : item.field === 'from_port_code' || item.field === 'to_port_code' ? (
                                        <Select
                                            showSearch
                                            value={editableData[item.field]}
                                            onSearch={handleSearch}
                                            onChange={(value) => handleInputChange(item.field, value, item.value)}
                                            onBlur={handleBlur}
                                            filterOption={false}
                                            style={{ width: 130 }}
                                            searchValue={searchValue}
                                            onInputKeyDown={handleKeyDown}
                                        >
                                            {portSuggestions.map((port) => (
                                                <Option key={port.port_code} value={port.port_code}>
                                                    {port.port_code}
                                                </Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <input
                                            type="text"
                                            value={editableData[item.field]}
                                            onChange={(e) => handleInputChange(item.field, e.target.value, item.value)}
                                            onBlur={handleBlur}
                                            style={{ width: 130 }}
                                        />
                                    )
                                ) : (
                                    <p className='data_values' style={{ display: 'block', marginRight: '10px' }}>
                                        {editableData[item.field] ? editableData[item.field] : item.value}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                ))}

            </div>
            <div style={{ textAlign: "end" }}>
                <Button
                    type="primary"
                    style={{
                        backgroundColor: '#FFFFFF',
                        color: '#06C270',
                        fontWeight: '400',
                        border: "1px solid #06C270",
                        marginBottom: '10px'
                    }}
                    disabled={!hasChanges}
                    onClick={() => handleSave()}
                    icon={<FileImageOutlined />}
                >
                    Save
                </Button>
            </div>
            <Modal
                title="User Remark"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="Save"
                cancelText="Cancel"
            >
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        name="remark"
                        label="User Remark"
                        rules={[{ required: true, message: 'Please enter a remark!' }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default StepperFirstBasicDetails;
