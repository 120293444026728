import { useDispatch, useSelector } from 'react-redux'

import React, { useEffect } from 'react'
import { AnnualVesselAnalysis } from '../../features/CasperAnalysis/CasperAnalysisSlice'

export const DataContainer = ({ iMONumber, voyageYear }) => {

  const dispatch = useDispatch()

  // console.log("iMONumber is", iMONumber);
  // console.log("voyageYear", voyageYear);

  const { fetchvesselAnanlysisDataStatus, vesselAnalysisData } = useSelector((state) => state.casperAnalysis)
  // console.log("fetchvesselAnanlysisDataStatus", fetchvesselAnanlysisDataStatus);

  useEffect(() => {
    if (iMONumber && voyageYear) {
      dispatch(AnnualVesselAnalysis({ iMONumber, voyageYear }));
    }
  }, [dispatch, iMONumber, voyageYear]);

  if (fetchvesselAnanlysisDataStatus === 'loading') {
    return <div>Loading...</div>
  }

  if (fetchvesselAnanlysisDataStatus === 'failed') {
    return <div>Failed to load data</div>;
  }

  const { compliance_balance, ghg_intensity, eua, penalty } = vesselAnalysisData;

  return (
    <div className='casper-analysis-data-container' style={{ position: 'relative', paddingBottom: '30px' }}>
      <div className='casper-analysis-data-element'>
        <p>Compliance Balance</p>

        {compliance_balance ? <h3>{compliance_balance.toFixed(3)} gCO₂eq</h3> : <h3>-</h3>}

      </div>
      <div className='casper-analysis-data-element'>
        <p>GHG Intensity</p>
        {ghg_intensity ? <h3>{ghg_intensity.toFixed(3)} gCO₂/MJ</h3> : <h3>-</h3>}
      </div>
      <div className='casper-analysis-data-element'>
        <p>EUA</p>
        {eua ? <h3>{eua.toFixed(3)} Euros</h3> : <h3>-</h3>}
      </div>
      <div className='casper-analysis-data-element'>
        <p>Penalty</p>
        {penalty ? <h3>{penalty} Euros</h3> : <h3>-</h3>}
      </div>
      <div style={{
        color: "#678DFF",
        fontStyle: "italic",
        fontSize: "1.5vh",
        position: 'absolute',
        bottom: '10px',
        left: '0',
        paddingLeft: '3px'
      }}>*Values depends on IMO no. & Year of voyage </div>
    </div>
  )
}