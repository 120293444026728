import React from 'react';
import VesselFilter from './vesselFilter';  // Ensure this file exists
import VesselPage from './vesselPage';  // Ensure this file exists

const VesselAnalysis = () => {
  return (
    <div className='vessel-analysis-pages'>
      <div className='vessel-analysis-page'>
        <VesselFilter />
      </div>
      <div className='vessel-analysis-page2'>
        <VesselPage />
      </div>
    </div>
  );
}

export default VesselAnalysis;
