import React from 'react';
import { useSelector } from 'react-redux';

const OutputResults = () => {
  console.log("inside OutputResults")
  const { calculationsData } = useSelector((state) => state.rawData);
  console.log(calculationsData)
  const hasData = calculationsData &&
    Object.keys(calculationsData).length > 0;

  const outputResultsToShow = [
    { label: 'AER', value: calculationsData ? calculationsData.cii.toFixed() : 'No Data' },
    { label: 'EU Identifier', value: calculationsData ? calculationsData.identifier : 'No Data' },
    { label: 'Compliance Balance', value: calculationsData ? `${calculationsData.ghg_result[0].cb.toFixed(3)} gCO₂eq ` : 'No Data' },
    { label: <>CO<sub>2 </sub> Eq Emissions Ets</>, value: calculationsData ? `${calculationsData.total_emission_eu_ets.toFixed(3)} MT` : 'No Data' },
    { label: 'CII Rating', value: calculationsData ? calculationsData.technical_grade : 'No Data' },
    { label: 'EEOI', value: calculationsData ? `${calculationsData.eeoi} gCO₂/Tonne-km` : 'No Data' },
    { label: 'GHG Intensity', value: calculationsData ? `${calculationsData.ghg_result[0].total_ghg.toFixed(3)} gCO₂/MJ ` : 'No Data' },
    { label: <>CO<sub>2</sub> Eq Emissions</>, value: calculationsData ? `${calculationsData.total_emission.toFixed(3)} MT` : 'No Data' },
    { label: 'EUA', value: calculationsData ? `${calculationsData.total_eua.toFixed(3)} Euros` : 'No Data' },
    { label: 'Penalty', value: calculationsData ? `${calculationsData?.ghg_result[0].penalty?.toFixed(3)} Euros` : 'No Data' },
  ];

  // Split the output results into chunks of 3 items each
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const chunks = chunkArray(outputResultsToShow, 3);

  return (
    <div>
      <div className='OutputResults'>
        {chunks.map((chunk, rowIndex) => (
          <div className='OutputResults-content' key={rowIndex}>
            {chunk.map((item, colIndex) => (
              <div className='OutputResults-content-heading' key={colIndex}>
                <p className='OutputResults-content-heading-p'>{item.label}</p>
                <p className='OutputResults-content-heading-p-2'>{item.value}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OutputResults;
