import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Modal } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../css/dataRecords.css';
import DataRecordsDescription from './DataRecordsDescription';
import { togglePastRecords, fetchFuelInfoByUniqueID } from '../../../features/RawData/RawDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '../../../assets/icons/voyages/edit.svg';
import AddAlternativeFuel from '../../../components/Modals/AddAlternativeFuel'; // Import AddAlternativeFuel modal
import EditCasperID from '../../../components/Modals/EditCasperID'; // Import EditCasperID modal
import FuelEditModal from '../sequentialFlow/FuelEditModal';

const DataRecordsTable = ({ selectedCards, handleCardSelect, selectedUniqueIdAuditTrace, setSelectedUniqueIdAuditTrace }) => {
    const [editingStates, setEditingStates] = useState({});
    const [editingValues, setEditingValues] = useState({});
    const { status, cardUniqueIDDetails, cardDataToShow, cardDataTypeIndividual } = useSelector((state) => state.rawData);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFuelUniqueID, setSelectedFuelUniqueID] = useState('');
    const [isEditCasperIDModalVisible, setIsEditCasperIDModalVisible] = useState(false); // State for Edit Casper ID modal
    // console.log(rawData)

    const dispatch = useDispatch()

    // console.log(selectedCards)
    const handleInputChange = (casperID, e) => {
        setEditingValues({
            ...editingValues,
            [casperID]: e.target.value
        });
    };

    const handleEditClick = (casperID) => {
        setEditingStates({
            ...editingStates,
            [casperID]: true
        });
        setEditingValues({
            ...editingValues,
            [casperID]: cardUniqueIDDetails.find(record => record.casper_id === casperID).casper_id
        });
    };

    const handleSaveClick = (casperID) => {
        setEditingStates({
            ...editingStates,
            [casperID]: false
        });

    };

    const showEditCasperIDModal = () => {
        setIsEditCasperIDModalVisible(true);
    };

    const handleEditCasperIDCancel = () => {
        setIsEditCasperIDModalVisible(false);
    };

    const handleEditCasperIDSubmit = () => {
        setIsEditCasperIDModalVisible(false);
        // Logic to handle submission of edited Casper ID
    };

    const handlePastRecords = (uniqueId) => {
        setSelectedUniqueIdAuditTrace(uniqueId)
        dispatch(togglePastRecords())
    }

    const showFuelEditModal = (unique_id) => {
        setSelectedFuelUniqueID(unique_id);
        dispatch(fetchFuelInfoByUniqueID(unique_id));
        setIsModalVisible(true);
    }

    // console.log(cardUniqueIDDetails)

    return (
        <div style={{ height: "88vh", overflow: "auto" }}>
            {cardUniqueIDDetails.map((record, index) => (
                <div
                    key={index}
                    className={`vessel-description-card ${selectedCards.includes(record.casper_id) ? 'selected' : ''}`}
                    style={{ border: selectedCards.includes(record.unique_id) ? '2px solid #272727' : '1px solid #D9D9D9', marginBottom: '10px', borderRadius: '16px' }}
                >
                    <div className="vessel-describeHead" style={{ width: '280px', border: '1px solid #D9D9D9', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
                        <Form layout="horizontal" className='dataRecordsForm' layout="vertical">
                            <Form.Item>
                                <Checkbox checked={selectedCards.includes(record.unique_id)} onChange={() => handleCardSelect(record.unique_id)}>Select</Checkbox>
                            </Form.Item>
                            <Form.Item label="Casper ID" >
                                <Input
                                    value={editingStates[record.casper_id] ? editingValues[record.casper_id] : record.casper_id || ''}
                                    readOnly={!editingStates[record.casper_id]}
                                    onChange={(e) => handleInputChange(record.casper_id, e)}
                                // addonAfter={
                                //     <img src={EditIcon} alt="edit" onClick={() => showEditCasperIDModal(record.casper_id)} style={{ cursor: 'pointer' }} />
                                // }
                                />
                            </Form.Item>
                            <Form.Item label="IMO No.">
                                <Input value={record.imo_number_id} readOnly />
                            </Form.Item>
                            {/* <Form.Item
                            //  label="Unique ID"
                            >
                                <Input value={record.unique_id} readOnly />
                            </Form.Item> */}
                            <Form.Item className='dataRecordsButtons'>
                                <Button className='custom-button' onClick={() => handlePastRecords(record.unique_id)}>Past Records</Button>
                                <Button className='custom-button' onClick={() => showFuelEditModal(record.unique_id)}>Fuels <EditOutlined /></Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='vessel-description' style={{ width: '100%', paddingTop: '0', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
                        <DataRecordsDescription rawDetailsData={cardDataToShow[record.unique_id]} dataType={cardDataTypeIndividual[record.unique_id]} uniqueID={record.unique_id} />
                    </div>
                </div>
            ))}

            {/* EditCasperID Modal */}
            <EditCasperID
                isVisible={isEditCasperIDModalVisible}
                handleCancel={handleEditCasperIDCancel}
                handleSubmit={handleEditCasperIDSubmit}
            />

            <FuelEditModal
                visible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                selectedUniqueId={selectedFuelUniqueID}
                selectedCards={selectedCards}
            />


        </div>
    );
};

export default DataRecordsTable;
