import React, { useEffect, useState } from 'react'
import SequentialFlowList from './SequentialFlowList'
import StepperFirst from './StepperFirst'
import StepperSecond from './StepperSecond'
import StepperThird from './StepperThird'
import StepperHeader from './StepperHeader'
import StepperFooter from './StepperFooter'

const SequentialRecordsContainer = ({ currentStep, onStepChange, selectedCards, setSelectedCards, setShowStepperCard, selectedCasperId, setSelectedCasperId }) => {
  const [selectedUniqueId, setSelectedUniqueId] = useState(selectedCards[0])

  // console.log(selectedCards)
  let StepperComponent;

  switch (currentStep) {
    case 0:
      StepperComponent = <StepperFirst selectedUniqueId={selectedUniqueId} selectedCards={selectedCards} />;
      break;
    case 1:
      StepperComponent = <StepperSecond selectedUniqueId={selectedUniqueId} selectedCasperId={selectedCasperId} setSelectedCasperId={setSelectedCasperId} selectedCards={selectedCards} />;
      break;
    case 2:
      StepperComponent = <StepperThird selectedUniqueId={selectedUniqueId} />;
      break;
    default:
      <></>;
  }

  return (
    <div className='Sequential-Flow-Container'>
      <SequentialFlowList currentStep={currentStep} selectedCards={selectedCards} setSelectedUniqueId={setSelectedUniqueId} />
      <div className='card Sequential-Flow-Steps'>
        <StepperHeader currentStep={currentStep} selectedUniqueId={selectedUniqueId} selectedCards={selectedCards} setSelectedCards={setSelectedCards} setSelectedUniqueId={setSelectedUniqueId} />
        <div className='Sequential-Flow-Card'>
          {StepperComponent}
        </div>
        <StepperFooter onStepChange={onStepChange} currentStep={currentStep} selectedCasperId={selectedCasperId} setSelectedCasperId={setSelectedCasperId} selectedCards={selectedCards} setShowStepperCard={setShowStepperCard} setSelectedCards={setSelectedCards} />
      </div>
    </div>
  );
};

export default SequentialRecordsContainer
