import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const fetchRawData = createAsyncThunk(
  'rawData/getRawData',
  async (params) => {

    // console.log(params)
    const param = {
      "ship_owner": params.shipOwner,
      "vessel_name": params.vesselName,
      "year": params.year,
      "from": params.from,
      "to": params.to,
      "imo_number": params.imoNumber,
      "content": params.requestedData,
      "casperID": params.casperID,
      "uniqueID": params.uniqueID,
      "docValue": params.docValue

      // "ship_owner": "someone",
      // "vessel_name": "Vesseel",
      // "year": 2024,
      // "from": "Non-EU",
      // "to": "EU",
      // "imo_number": 9929821,
      // "content": "VOYAGE_DATA",
      // "casperID": '111',
      // "uniqueID": ''
    }

    const request = await makeRequest("get", `api/ems/get_raw_data/?imo_number=${param.imo_number}&requested_data=${param.content}&year=${param.year}&ship_owner=${param.ship_owner}&vessel_name=${param.vessel_name}&from=${param.from}&to=${param.to}&casper_id=${param.casperID}&unique_id=${param.uniqueID}&doc=${param.docValue}`)
    const response = await request.data.data
    return response
  }
)

export const patchFuelInfoByUniqueID = createAsyncThunk(
  'rawData/patchFuelInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("patch", `api/ems/patch_fuel_info_by_unique_id/?unique_id=${params.uniqueID}&imo_number=${params.imo_number}&casper_id=${params.casper_id}`, params.payload)
    const response = await request.data
    return response
  }
)

export const postFuelInfoApplyToAll = createAsyncThunk(
  'rawData/postFuelInfoToAll',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("post", `api/ems/post_fuel_info_apply_to_all/?imo_number=${params.imo_number}&casper_id=${params.casper_id}`, params)
    const response = await request.data
    return response
  }
)
export const fetchFuelInfoByUniqueID = createAsyncThunk(
  'rawData/getFuelData',
  async (params) => {

    // console.log(params)
    const request = await makeRequest("get", `/api/ems/get_fuel_info_by_unique_id/?unique_id=${params}`)
    const response = await request.data.data
    return response
  }
)

export const fetchShipOwners = createAsyncThunk(
  'rawData/fetchShipOwners',
  async (param) => {
    const request = await makeRequest("get", `/api/ems/fetch_shipowner_suggestions/?doc=${param}`)
    const response = await request.data.data
    return response
  }
)

export const fetchVessels = createAsyncThunk(
  'rawData/fetchVessels',
  async (shipowner_name) => {
    const request = await makeRequest("get", `api/ems/fetch_vessel_suggestions/?shipowner_name=${shipowner_name}`)
    const response = await request.data.data;
    return response;
  }
)

export const fetchCasperID = createAsyncThunk(
  'rawData/fetchCasperID',
  async (imo_number) => {
    const request = await makeRequest("get", `api/ems/fetch_casperID_suggestions/?imo_number=${imo_number}`)
    const response = await request.data.data;
    return response;
  }
)

export const fetchAuditDates = createAsyncThunk(
  'rawData/fetchAuditDates',
  async (params) => {
    const endpoint = params.casperId
      ? `api/ems/fetch_audit_dates/?casper_id=${params.casperId}`
      : `api/ems/fetch_audit_dates/?unique_id=${params.uniqueId}`;

    const request = await makeRequest('get', endpoint);
    const response = await request.data;
    return response;
  }
);

export const getCasperIds = createAsyncThunk(
  'rawData/getcasperIds',
  async (params) => {
    const request = await makeRequest("get", `api/ems/get_casperid_lookup/?keyword=${params.imo_number}&unique_id=${params.unique_id}&imo_number=${params.imo_number}`)
    const response = await request.data;
    return response;
  }
)

export const putCasperId = createAsyncThunk(
  'rawData/putCasperId',
  async (params) => {
    const request = await makeRequest("put", `api/ems/put_casperid/`, params)
    const response = await request.data;
    return response;
  }
)

export const runCalculations = createAsyncThunk(
  'rawData/runCalculation',
  async (params) => {
    const formData = new FormData();
    formData.append("casper_id", params.casper_id);
    // console.log(params)
    const request = await makeRequest("post", `api/ems/run_calculation/`, formData)
    const response = await request.data;
    return response;
  }
)

export const updateVoyageInfo = createAsyncThunk(
  'rawData/updateVoyageInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("post", `api/ems/update_voyage_info/`, params)
    const response = await request.data;
    return response;
  }
)

export const getCalculationsResults = createAsyncThunk(
  'rawData/getResults',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("get", `api/ems/casper-id/get_agg_results/?casper_id=${params}`)
    const response = await request.data;
    return response;
  }
)

export const getPortNames = createAsyncThunk(
  'rawData/getPortNames',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("get", `api/ems/ports/get_port_code_suggestions/?keyword=${params}`)
    const response = await request.data;
    return response;
  }
)

export const getAuditDetails = createAsyncThunk(
  'rawData/getAuditDetails',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("get", `api/ems/get_audit/${params.id}/?req_type=${params.req_type}`)
    const response = await request.data;
    return response;
  }
)

export const postBlandedFuelInfo = createAsyncThunk(
  'rawData/postBlandedFuelInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("post", `api/ems/post_blended_fuel_info/`, params)
    const response = await request.data;
    return response;
  }
)

export const getBlandedFuelInfo = createAsyncThunk(
  'rawData/getBlandedFuelInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("get", `api/ems/get_blend_info/?fuel_metadata_id=${params}`)
    const response = await request.data;
    return response;
  }
)

export const patchBlandedFuelInfo = createAsyncThunk(
  'rawData/patchBlandedFuelInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("patch", `api/ems/update_blend_info/`, params)
    const response = await request.data;
    return response;
  }
)

const rawDataSlice = createSlice({
  name: "rawData",
  initialState: {
    status: 'idle',
    rawData: [],
    cardDataToShow: {},
    cardDataTypeIndividual: {},
    fuelDataByUniqueId: [],
    count: null,
    page: 1,
    shipOwners: [],
    shipOwnersStatus: '',
    vesselDetails: [],
    vesselStatus: '',
    casperID: [],
    casperIDStatus: '',
    cardUniqueIDDetails: [],
    dataType: 'VOYAGE_DATA',
    auditDates: [],
    auditDateStatus: '',
    casperIdStatus: '',
    casperIdData: {},
    calculationsStatus: '',
    calculationsData: {},
    conflictingStatus: '',
    conflictingData: {},
    conflictingPayload: {},
    calculationsResult: {},
    portSuggestions: [],
    auditDetails: [],
    pastRecords: false,
    blendedFuelsData: {},
    blendedFuelsGetData: [],
    blendedFuelsPatchData: {},
  },
  reducers: {
    resetRawDataState: (state) => {
      state.page = 1;
      state.rawData = [];
    },
    togglePastRecords: (state) => {
      state.pastRecords = !state.pastRecords;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRawData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRawData.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.rawData = action.payload.reduce((acc, item) => {
          acc[item.unique_id] = [item];
          return acc;
        }, {});

        state.dataType = action.meta.arg
        // console.log(action.meta.arg.requestedData)
        // console.log(state.dataType)
        if (action.meta.arg.requestedData === "VOYAGE_DATA" && action.meta.arg.uniqueID === "") {
          state.cardUniqueIDDetails = action.payload;

          // Populate cardDataToShow with unique_id as keys
          state.cardDataToShow = action.payload.reduce((acc, item) => {
            acc[item.unique_id] = [item];
            return acc;
          }, {});
          // Populate  cardDataTypeIndividual with unique_id as keys
          state.cardDataTypeIndividual = action.payload.reduce((acc, item) => {
            acc[item.unique_id] = action.meta.arg;
            return acc;
          }, {});
        }

        if (action.meta.arg.uniqueID !== "") {
          const updatedCardDataToShow = {
            ...state.cardDataToShow,
            [action.meta.arg.uniqueID]: action.payload
          };
          const updatedDataTypeToShow = {
            ...state.cardDataTypeIndividual,
            [action.meta.arg.uniqueID]: action.meta.arg
          };

          state.cardDataToShow = updatedCardDataToShow;
          state.cardDataTypeIndividual = updatedDataTypeToShow;
        }
        // console.log(state.cardDataToShow)
        // console.log(state.cardDataTypeIndividual)

        state.page++
        state.page++
      })
      .addCase(fetchRawData.rejected, (state) => {
        state.status = 'failed';
        state.rawData = []
      })
      .addCase(fetchFuelInfoByUniqueID.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFuelInfoByUniqueID.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.fuelDataByUniqueId = action.payload
      })
      .addCase(fetchFuelInfoByUniqueID.rejected, (state) => {
        state.status = 'failed';
        state.fuelDataByUniqueId = []
      })
      .addCase('rawData/resetRawDataState', (state) => {
        // Reset page to 1 and clear notification array
        state.page = 1;
        state.rawData = [];
      })
      .addCase(fetchShipOwners.pending, (state) => {
        state.shipOwnersStatus = 'loading';
      })
      .addCase(fetchShipOwners.fulfilled, (state, action) => {
        if (action.payload !== "") {
          const uniqueNames = new Set(action.payload.data);
          action.payload.forEach(name => {
            uniqueNames.add(name);
          });
          state.shipOwners = Array.from(uniqueNames);
          state.shipOwnersStatus = 'succeeded';
        }
      })
      .addCase(fetchShipOwners.rejected, (state) => {
        state.shipOwnersStatus = 'failed';
      })

      .addCase(fetchVessels.pending, (state) => {
        state.vesselStatus = 'loading';
      })
      .addCase(fetchVessels.fulfilled, (state, action) => {
        state.vesselStatus = 'succeeded';
        state.vesselDetails = action.payload
      })
      .addCase(fetchVessels.rejected, (state) => {
        state.vesselStatus = 'failed';
      })

      .addCase(fetchCasperID.pending, (state) => {
        state.casperIDStatus = 'loading';
      })
      .addCase(fetchCasperID.fulfilled, (state, action) => {
        state.casperIDStatus = 'succeeded';
        state.casperID = action.payload
        // console.log(state.casperID)
      })
      .addCase(fetchCasperID.rejected, (state) => {
        state.casperIDStatus = 'failed';
      })

      .addCase(fetchAuditDates.pending, (state) => {
        state.auditDateStatus = 'loading';
      })
      .addCase(fetchAuditDates.fulfilled, (state, action) => {
        state.auditDateStatus = 'succeeded';
        state.auditDates = action.payload
      })
      .addCase(fetchAuditDates.rejected, (state) => {
        state.auditDateStatus = 'failed';
      })

      .addCase(getCasperIds.pending, (state) => {
        state.casperIDStatus = 'loading';
      })
      .addCase(getCasperIds.fulfilled, (state, action) => {
        state.casperIDStatus = 'succeeded';
        state.casperIdData = action.payload.data
      })
      .addCase(getCasperIds.rejected, (state) => {
        state.casperIDStatus = 'failed';
      })
      .addCase(runCalculations.pending, (state) => {
        state.calculationsStatus = 'loading';
      })
      .addCase(runCalculations.fulfilled, (state, action) => {
        state.calculationsStatus = 'succeeded';
        state.calculationsData = action.payload.data
        // console.log(action.payload)
      })
      .addCase(runCalculations.rejected, (state) => {
        state.calculationsStatus = 'failed';
      })
      .addCase(postFuelInfoApplyToAll.pending, (state) => {
        state.conflictingStatus = 'loading';
      })
      .addCase(postFuelInfoApplyToAll.fulfilled, (state, action) => {
        state.conflictingStatus = 'succeeded';
        state.conflictingData = action.payload.data
        state.conflictingPayload = action.meta.arg
        // console.log(action.meta.arg)
      })
      .addCase(postFuelInfoApplyToAll.rejected, (state) => {
        state.conflictingStatus = 'failed';
      })
      .addCase(getCalculationsResults.pending, (state) => {
        // state.conflictingStatus = 'loading';
      })
      .addCase(getCalculationsResults.fulfilled, (state, action) => {
        // state.conflictingStatus = 'succeeded';
        state.calculationsResult = action.payload.data[0]
      })
      .addCase(getCalculationsResults.rejected, (state) => {
        // state.conflictingStatus = 'failed';
      })
      .addCase(getPortNames.pending, (state) => {
        // state.conflictingStatus = 'loading';
      })
      .addCase(getPortNames.fulfilled, (state, action) => {
        // state.conflictingStatus = 'succeeded';
        state.portSuggestions = action.payload.data
      })
      .addCase(getPortNames.rejected, (state) => {
        // state.conflictingStatus = 'failed';
      })
      .addCase(getAuditDetails.pending, (state) => {

      })
      .addCase(getAuditDetails.fulfilled, (state, action) => {

        state.auditDetails = action.payload.data
        // console.log(action.payload)
      })
      .addCase(getAuditDetails.rejected, (state) => {

      })
      .addCase(postBlandedFuelInfo.fulfilled, (state, action) => {

        state.blendedFuelsData = action.payload.final_res
        console.log(action.payload.final_res)
      })
      .addCase(getBlandedFuelInfo.fulfilled, (state, action) => {

        state.blendedFuelsGetData = action.payload
        console.log(action.payload)
      })
      .addCase(patchBlandedFuelInfo.fulfilled, (state, action) => {

        state.blendedFuelsData = action.payload.data
        console.log(action.payload)
      })
  },
})

export const { resetRawDataState, togglePastRecords } = rawDataSlice.actions;

export default rawDataSlice.reducer;