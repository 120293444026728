import React from 'react';
import { useSelector } from 'react-redux';

const DataFuelRecordsDetails = ({ rawDetailsData, dataType }) => {
    const standardFuels = ['MDO', 'LFO', 'HFO', 'LNG-ME', 'LNG-BOILER', 'LNG-AE', 'LNG-OTHERS', 'LPG-PROPANE', 'LPG-BUTANE'];
    let data = {
        "Standard Fuels": rawDetailsData.filter(item => standardFuels.includes(item.fuel_id__name)),
        "Alternate Fuels": rawDetailsData.filter(item => !standardFuels.includes(item.fuel_id__name))
    };

    // console.log(rawDetailsData)



    const renderFuels = (fuels) => {
        const columns = [];
        for (let i = 0; i < fuels.length; i += 2) {
            columns.push(fuels.slice(i, i + 2));
        }

        return columns.map((column, columnIndex) => (
            <div key={columnIndex} style={{ display: 'flex', flexDirection: 'column', marginRight: '30px', gap: '20px' }}>
                {column.map((item, index) => (
                    <div key={index} style={{ marginBottom: '25px' }}>
                        <p className='data_labels' style={{ display: 'block' }}>{!item.fuel_id__name ? '-' : item.fuel_id__name}</p>
                        <p className='data_values' style={{ display: 'block' }}>
                            {(() => {
                                let value;
                                switch (dataType.requestedData) {
                                    case 'ROB_DATA':
                                        value = `${item.rob} MT`;
                                        break;
                                    case 'FUEL_CONSUMPTION_DATA':
                                        value = `${item.consumption} MT`;
                                        break;
                                    case 'BUNKER_SURVEY_DATA':
                                        value = `${item.bunker_survey} MT`;
                                        break;
                                    case 'BUNKER_QTY_DATA':
                                        value = `${item.bunker_quantity} MT`;
                                        break;
                                    case 'LCV_DATA':
                                        value = `${item.fuel_metadata__lcv} MJ/MT`;
                                        break;
                                    case 'CSLIP_DATA':
                                        value = `${item.fuel_metadata__cslip} MT`;
                                        break;
                                    case 'EF_TTW_DATA':
                                        value = dataType.selected_button === 'MJfuel'
                                            ? `${item.fuel_metadata__ef_ttw_MJfuel?.toFixed(2)} gCO₂eq /MJ`
                                            : `${item.fuel_metadata__ef_ttw_gfuel?.toFixed(2)} gCO₂eq /MJ`;
                                        break;
                                    case 'EF_WTT_DATA':
                                        value = dataType.selected_button === 'MJfuel'
                                            ? `${item.fuel_metadata__ef_wtt_MJfuel?.toFixed(2)} gCO₂eq /MJ`
                                            : `${item.fuel_metadata__ef_wtt_gfuel?.toFixed(2)} gCO₂eq /MJ`;
                                        break;
                                    default:
                                        value = '-';
                                        break;
                                }
                                return value ?? '-';
                            })()}
                        </p>
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <div style={{ display: 'flex', marginTop: '15px' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '30px', borderRight: '0.5px solid #BEBEBE' }}>
                    <p className='data_heading'>Standard Fuels</p>
                    <div style={{ display: 'flex' }}>
                        {renderFuels(data["Standard Fuels"])}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div>
                    <p className='data_heading'>Alternate Fuels</p>
                    <div style={{ display: 'flex' }}>
                        {renderFuels(data["Alternate Fuels"])}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataFuelRecordsDetails;
