import React , {useEffect, useState } from 'react';
import { Modal, Button, Tag } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { runCalculations } from '../../features/RawData/RawDataSlice';


const Results = ({ visible, onClose }) => {

  const dispatch = useDispatch();
  const { calculationsResult , calculationsStatus, calculationsData } = useSelector(state => state.rawData);
  const [isResultSynced, setIsResultSynced] = useState(calculationsResult.is_result_synced);

  let rerunApiFlag = false;

  const calculationsPayload = {
    casper_id: calculationsResult?.casper_id,
  };

  // Rerun calculations api call when the rerun button is clicked
  const handleRerunClick = async () => {
    await dispatch(runCalculations(calculationsPayload));
    if(calculationsStatus !== 'failed'){
      rerunApiFlag = true;
      setIsResultSynced(true);
    }else{
      console.log("api call failed");
    }
  };

  const modalStyle = {
    fontFamily: 'Aeonik TRIAL',
    color: '#676262',
    fontSize: '12px',

  };
  const modalStyles = {
    fontFamily: 'Aeonik TRIAL',
    fontWeight: 400,
    marginTop: '10px',

  };

  const resultsHeadingStyle = {
    fontSize: '18px',
    lineHeight: '20.52px',
    color: '#272727',
  };

  const casperIdStyle = {
    ...modalStyle, ...modalStyles,
    fontStyle: 'italic',
    fontSize: '10px',
    lineHeight: '11.4px',
    color: '#737373',
    textAlign: 'right',
  };

  const contentStyle = {
    ...modalStyle, ...modalStyles,
    fontSize: '16px',
    lineHeight: '18.24px',
    color: '#678DFF',
  };

  const divContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    Height: '50vh', // Adjusted minimum height for modal content
  };

  const divColumnStyle = {
    flex: '1',
    marginRight: '10px',
  };

  const divItemStyle = {
    backgroundColor: '#678DFF0D',
    borderRadius: '5px',
    margin: '5px',
    padding: '10px',
    gap: '10px',
    marginBottom: '16px',
  };

  const closeButtonStyle = {
    backgroundColor: '#678DFF',
    borderColor: '#678DFF',
    color: '#FFFFFF',
    marginRight: '10px',
  };

  return (
    <Modal
      title={
        <div style={resultsHeadingStyle}>
          Results
          <div style={{ ...casperIdStyle }}>Casper ID: {calculationsResult?.casper_id}</div>
        </div>
      }
      visible={visible}

      onCancel={onClose}
      footer={[
        <div key="footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
          
          <Button key="close" style={closeButtonStyle} onClick={onClose}>
            Close
          </Button>
        </div>,
      ]}
      style={{ minWidth: '700px', gap: '10px' }}
    >

      { isResultSynced === true ? (
          <div style={contentStyle}>
        <div style={divContainerStyle}>
          <div style={divColumnStyle}>
            <div style={divItemStyle}>
              <p style={modalStyle}>AER Ratio</p>
              <p style={modalStyles} > {calculationsResult?.cii?.toFixed(3)} </p>
            </div>
            <div style={divItemStyle}>
              <p style={modalStyle}>EU Identifier</p>
              <p style={modalStyles}>{calculationsResult?.identifier}</p>
            </div>
            <div style={divItemStyle}>
              <p style={modalStyle}>Compliance Balance</p>
              <p style={modalStyles}>{calculationsResult?.compliance_balance?.toFixed(3)}</p>
            </div>
            <div style={divItemStyle}>
              <p style={modalStyle}>CO<sub>2</sub> Eq Emissions ETS</p>
              <p style={modalStyles}>{calculationsResult?.co2eq_emissions_ets?.toFixed(3)}</p>
            </div>
            <div style={divItemStyle}>
              <p style={modalStyle}>CII</p>
              <p style={modalStyles}>
                <Tag
                  color={
                    calculationsResult?.technical_grade === 'A'
                      ? 'rgb(113, 189, 126)'
                      : calculationsResult?.technical_grade === 'B'
                        ? 'rgb(214, 233, 201)'
                        : calculationsResult?.technical_grade === 'C'
                          ? 'rgb(249, 226, 143)'
                          : calculationsResult?.technical_grade === 'D'
                            ? 'rgb(251, 212, 193)'
                            : calculationsResult?.technical_grade === 'E'
                              ? 'rgb(255, 133, 133)'
                              : 'rgba(12, 200, 118, 0.20)'
                  }

                  style={{ color: "black" }}
                >
                  {calculationsResult?.technical_grade}
                </Tag>
              </p>
            </div>
          </div>
          <div style={divColumnStyle}>
            <div style={divItemStyle}>
              <p style={modalStyle}>EEOI</p>
              <p style={modalStyles}>{calculationsResult?.eeoi}</p>
            </div>
            <div style={divItemStyle}>
              <p style={modalStyle}>GHG Intensity</p>
              <p style={modalStyles}>{calculationsResult?.ghg_intensity?.toFixed(3)}</p>
            </div>
            <div style={divItemStyle}>
              <p style={modalStyle}>CO<sub>2</sub> Eq Emissions</p>
              <p style={modalStyles}>{calculationsResult?.co2eq_emissions?.toFixed(3)}</p>
            </div>
            <div style={divItemStyle}>
              <p style={modalStyle}>EUA</p>
              <p style={modalStyles}>{calculationsResult?.eua?.toFixed(3)}</p>
            </div>
          </div>
        </div>
        <span style={{ fontStyle: 'italic', fontSize: '12px', lineHeight: '13.68px', color: '#06C270' }}>If the above values seem fine, please lock the record.</span>
      </div> 
      ) :
      (
        <>
              <div style={{ display: "flex",flexDirection: "column",alignItems: "center",justifyContent: "center",gap: "1rem",marginTop: "3rem",}}>
                <p>
                  Your casper results are not synced. Please rerun calculations.
                </p>
                <div onClick={handleRerunClick} className="reset-btn">
                  Rerun Calculation
                </div>
              </div>
        </>
      )
      }  

    </Modal>
    
  );
};

export default Results;
