import React, { useState } from 'react';
import { Button, Dropdown, Menu, Modal, Form, Input } from 'antd';
import { DownOutlined, FileImageOutlined } from '@ant-design/icons';
import DataRecordsDetails from './DataRecordsDetails';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRawData, updateVoyageInfo } from '../../../features/RawData/RawDataSlice';
import DataFuelRecordsDetails from './DataFuelRecordsDetails';
const item1 = { label: 'EF(TTW - MJfuel)', key: 'EF_TTW_DATA1' };
const item2 = { label: 'EF(WTT - MJfuel)', key: 'EF_WTT_DATA1' };
const item3 = { label: 'EF(TTW - gfuel)', key: 'EF_TTW_DATA2' };
const item4 = { label: 'EF(WTT - gfuel)', key: 'EF_WTT_DATA2' };
const bunker1 = { label: 'Survey', key: 'BUNKER_SURVEY_DATA' };
const bunker2 = { label: 'Quantity', key: 'BUNKER_QTY_DATA' };

const emissionDropdown = [item1, item2, item3, item4];
const bunkerSurveyDropdown = [bunker1, bunker2];

const DataRecordsDescription = ({ rawDetailsData, dataType, uniqueID }) => {
  const dispatch = useDispatch()

  // console.log(dataType)

  const dataToolbar = [
    { item: 'Voyage', dropdown: false, content: 'VOYAGE_DATA' },
    { item: 'Vessel', dropdown: false, content: 'VESSEL_DATA' },
    { item: 'Cargo', dropdown: false, content: 'CARGO_DATA' },
    { item: 'Consumption', dropdown: false, content: 'FUEL_CONSUMPTION_DATA' },
    { item: 'ROB', dropdown: false, content: 'ROB_DATA' },
    { item: 'EF (TTW)', dropdown: true, menu: emissionDropdown, content: 'This is the Emission Factor (TTW) data.' },
    { item: 'Bunker', dropdown: true, menu: bunkerSurveyDropdown, content: 'This is the Bunker Survey data.' },
    { item: 'LCV', dropdown: false, content: 'LCV_DATA' },
    { item: 'CSlip', dropdown: false, content: 'CSLIP_DATA' },
  ];

  const [selectedButton, setSelectedButton] = useState(dataToolbar[0].item);
  const [selectedContent, setSelectedContent] = useState(dataToolbar[0].content);
  const [dropdownSelections, setDropdownSelections] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const handleButtonClick = (item, content) => {
    setSelectedButton(item);
    setSelectedContent(content);

    const params = {
      shipOwner: dataType.shipOwner,
      vesselName: dataType.vesselName,
      year: dataType.year,
      from: dataType.from,
      to: dataType.to,
      imoNumber: dataType.imoNumber,
      requestedData: content,
      casperID: dataType.casperID,
      uniqueID: uniqueID,
      docValue: dataType.docValue
    }
    dispatch(fetchRawData(params))
  };

  const handleMenuClick = (menuItem, toolbarItem) => {
    setDropdownSelections({ ...dropdownSelections, [toolbarItem.item]: menuItem.label });
    setSelectedButton(toolbarItem.item);
    setSelectedContent(`This is the ${menuItem.label} data.`);


    const endIndex = menuItem.label.indexOf(')');

    const extractedString = menuItem.label.substring(endIndex - 6, endIndex);
    let dataTypess = menuItem.key
    if (menuItem.key === "EF_TTW_DATA1" || menuItem.key === "EF_TTW_DATA2" || menuItem.key === "EF_WTT_DATA1" || menuItem.key === "EF_WTT_DATA2") {
      dataTypess = menuItem.key.substring(0, menuItem.key.length - 1)
    }
    const params = {
      selected_button: extractedString,
      shipOwner: dataType.shipOwner,
      vesselName: dataType.vesselName,
      year: dataType.year,
      from: dataType.from,
      to: dataType.to,
      imoNumber: dataType.imoNumber,
      requestedData: dataTypess,
      casperID: dataType.casperID,
      uniqueID: uniqueID,
      docValue: dataType.docValue
    }

    dispatch(fetchRawData(params))
  };

  const handleEdit = (field, value, oldValue, nameField, newName, oldName) => {
    // console.log(field)
    setEditedData({
      ...editedData,
      [field]: {
        initial_value: oldValue,
        final_value: value,
      },
      [nameField]: {
        initial_value: oldName,
        final_value: newName,
      },
    });
    setHasChanges(true);
  };

  const handleSave = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    form
      .validateFields()
      .then((values) => {
        // Handle form submission here
        console.log('User Remark:', values.remark);
        console.log(editedData)

        const params = {
          ...editedData,
          unique_id: "",
          remark: values.remark,
          unique_id: uniqueID
        }

        console.log(params)

        dispatch(updateVoyageInfo(params))
        // dispatch(fetchRawData(params))
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className='card' style={{ boxShadow: 'none', height: '100%' }}>
      <div className='dataRecordDescriptionHeader'>
        {dataToolbar.map((toolbarItem, index) => (
          <Dropdown
            key={index}
            overlay={
              toolbarItem.dropdown ? (
                <Menu onClick={(e) => handleMenuClick(toolbarItem.menu.find((m) => m.key === e.key), toolbarItem)} items={toolbarItem.menu} />
              ) : (
                <></>
              )
            }
            trigger={toolbarItem.dropdown ? ['click'] : []}
          >
            <Button
              type="default"
              style={{
                backgroundColor: selectedButton === toolbarItem.item ? '#272727' : '#FFFFFF',
                color: selectedButton === toolbarItem.item ? '#FFFFFF' : '#292D32',
                fontWeight: '400',
                marginRight: '10px',
                opacity: selectedButton === toolbarItem.item ? '1' : '0.6'
              }}
              onClick={() => !toolbarItem.dropdown && handleButtonClick(toolbarItem.item, toolbarItem.content)}
            >
              {dropdownSelections[toolbarItem.item] || toolbarItem.item} {toolbarItem.dropdown && <DownOutlined />}
            </Button>
          </Dropdown>
        ))}
      </div>
      <div>
        {dataType.requestedData == "VOYAGE_DATA" || dataType.requestedData == "VESSEL_DATA" || dataType.requestedData == "CARGO_DATA"
          ?
          <DataRecordsDetails rawDetailsData={rawDetailsData[0]} dataType={dataType} onEdit={handleEdit} />
          :
          <DataFuelRecordsDetails rawDetailsData={rawDetailsData} dataType={dataType} />
        }

      </div>

      <div style={{ textAlign: "end" }}>
        <Button
          type="primary"
          style={{
            backgroundColor: '#FFFFFF',
            color: '#06C270',
            fontWeight: '400',
            border: "1px solid #06C270"
          }}
          disabled={!hasChanges}
          onClick={() => handleSave()}
          icon={<FileImageOutlined />}
        >
          Save
        </Button>
      </div>
      {/* UserRemarkModal..move this modal in new file lready created  */}
      <Modal
        title="User Remark"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="remark"
            label="User Remark"
            rules={[{ required: true, message: 'Please enter a remark!' }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DataRecordsDescription;
