import React from 'react'
import { ReactComponent as Compare } from "../../assets/icons/rawdata/iconamoon_compare.svg"
import Indicators from './Indicators'
const OutputValue = ({ auditDetails, selectedRecordDateId }) => {

  const outputComparisonData = [
    {
      title: "CII",
      previous: auditDetails?.old_output?.cii?.toFixed(2),
      after: auditDetails?.new_output?.cii?.toFixed(2)
    },
    {
      title: "EUA",
      previous: auditDetails?.old_output?.eua?.toFixed(2),
      after: auditDetails?.new_output?.eua?.toFixed(2)
    },
    {
      title: "EEOI",
      previous: auditDetails?.old_output?.eeoi,
      after: auditDetails?.new_output?.eeoi
    },
    {
      title: "EUA Sea",
      previous: auditDetails?.old_output?.eua_sea,
      after: auditDetails?.new_output?.eua_sea
    },
    {
      title: "EUA Port",
      previous: auditDetails?.old_output?.eua_port,
      after: auditDetails?.new_output?.eua_port
    },
    {
      title: "EUA Anchor",
      previous: auditDetails?.old_output?.eua_anchor,
      after: auditDetails?.new_output?.eua_anchor
    },
    {
      title: "Identifier",
      previous: auditDetails?.old_output?.identifier,
      after: auditDetails?.new_output?.identifier
    },
    {
      title: "EUA Drifting",
      previous: auditDetails?.old_output?.eua_drifting,
      after: auditDetails?.new_output?.eua_drifting
    },
    {
      title: "EUA Bunkering",
      previous: auditDetails?.old_output?.eua_bunkering,
      after: auditDetails?.new_output?.eua_bunkering
    },
    {
      title: "GHG Intensity",
      previous: auditDetails?.old_output?.ghg_intensity?.toFixed(2),
      after: auditDetails?.new_output?.ghg_intensity?.toFixed(2)
    },
    {
      title: "CO₂ Equivalent Emissions",
      previous: auditDetails?.old_output?.co2eq_emissions?.toFixed(2),
      after: auditDetails?.new_output?.co2eq_emissions?.toFixed(2)
    },
    {
      title: "Technical Grade",
      previous: auditDetails?.old_output?.technical_grade,
      after: auditDetails?.new_output?.technical_grade
    },
    {
      title: "Compliance Balance",
      previous: auditDetails?.old_output?.compliance_balance?.toFixed(2),
      after: auditDetails?.new_output?.compliance_balance?.toFixed(2)
    },
    {
      title: "CO₂ Equivalent Emissions ETS",
      previous: auditDetails?.old_output?.co2eq_emissions_ets?.toFixed(2),
      after: auditDetails?.new_output?.co2eq_emissions_ets?.toFixed(2)
    },
  ]

  const hasData = auditDetails &&
    auditDetails.old_output &&
    auditDetails.new_output &&
    Object.keys(auditDetails.old_output).length > 0 &&
    Object.keys(auditDetails.new_output).length > 0;

  return (
    <div className='output-value'>
      <div className='output-value-header'>
        <Compare style={{ width: "3.5vh", height: "3.5vh" }} />
        <span>Output Value Comparison</span>
      </div>
      <div className='output-value-body'>
        {
          !hasData ?
            <div className='NoDataRecords'><p> No results available. It can be possible because Calculations were not run at this time. </p></div>
            :
            outputComparisonData.map((data, index) => (
              <Indicators
                key={index}
                title={data.title}
                previous={data.previous}
                after={data.after}
              />
            ))}

      </div>
    </div>
  )
}

export default OutputValue