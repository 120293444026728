import React, { useState, useEffect } from 'react';
import { Modal, Input, Button } from 'antd';
import { ReactComponent as EditOutlinedIcon } from '../../assets/icons/rawdata/EditOutlined.svg';

const EditCasperID = ({ isVisible, handleCancel, handleSubmit }) => {
  const [previousID, setPreviousID] = useState('');
  const [newID, setNewID] = useState('');

  useEffect(() => {
    if (!isVisible) {
      setPreviousID('');
      setNewID('');
    }
  }, [isVisible]);

  const onSubmit = () => {
    handleSubmit(previousID, newID);
    setPreviousID('');
    setNewID('');
  };

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
          <EditOutlinedIcon style={{ marginRight: 8 }} />
          Edit Casper ID
        </div>
      }
      visible={isVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} style={{ backgroundColor: '#E2E2E2' }}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmit} style={{ backgroundColor: '#678DFF', color: 'white' }}>
          Submit
        </Button>,
      ]}
    >
      <div>
        <label style={{ color: '#678DFF' }}>Previous ID</label>
        <Input
          value={previousID}
          onChange={(e) => setPreviousID(e.target.value)}
          placeholder="24324234234324"
        />
      </div>
      <div style={{ marginTop: 16 }}>
        <label style={{ color: '#678DFF' }}>Enter New ID</label>
        <Input
          value={newID}
          onChange={(e) => setNewID(e.target.value)}
          placeholder="eg. 24234324324"
        />
      </div>
    </Modal>
  );
};

export default EditCasperID;
