import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input } from 'antd';
import EditOutlined2 from '../../assets/icons/rawdata/EditOutlined2.svg';
import { fetchVesselData, updatePwindPprop } from '../../features/VesselsAnalysis/VesselsAnalysisSlice';
import { set } from 'date-fns';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const VesselTable = () => {
  const dispatch = useDispatch();
  const { vessels, total_pages, fetchVesselDataStatus, updatePwindPpropStatus } = useSelector((state) => state.vesselsAnalysis);
  // console.log("vessels", vessels);

  const [pageNumber, setPageNumber] = useState(1);
  const [editing, setEditing] = useState({ imo_number: null, field: null });
  const [editedValues, setEditedValues] = useState({});
  const [tempValue, setTempValue] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    dispatch(fetchVesselData({ requested_page: pageNumber }));
  }, [dispatch, pageNumber]);

  // useEffect(() => {
  //   if (vessels.length > 0) {
  //     const initialEditedValues = vessels.reduce((acc, vessel) => {
  //       acc[vessel.imo_number] = {
  //         pwind: vessel.pwind,
  //         pprop: vessel.pprop,
  //       };
  //       return acc;
  //     }, {});
  //     setEditedValues(initialEditedValues);
  //   }
  // }, [vessels]);

  useEffect(() => {
    if (vessels.length > 0) {
      const initialEditedValues = vessels.reduce((acc, vessel) => {
        acc[vessel.imo_number] = {
          pwind: vessel.pwind.toString(),
          pprop: vessel.pprop.toString(),
        };
        return acc;
      }, {});
      console.log('Initial edited values:', initialEditedValues);
      setEditedValues(initialEditedValues);
    }
  }, [vessels]);

  const handleTableChange = (page) => {
    setPageNumber(page);
  };

  // const handleEditClick = (imo_number, field) => {
  //   setEditing({ imo_number, field });
  //   // setTempValue('');
  //   console.log("imo_number inside handleEditclick is", imo_number);
  //   console.log("field inside handleEditclick is", field);

  //   setTempValue(editedValues[imo_number]?.field || '');
  //   // editedValues[record.imo_number]?.pwind || text
  //   console.log("Temp Value inside handleEditclick is", tempValue); 
  // };

  const handleEditClick = (imo_number, field) => {
    console.log("imo_number inside handleEditclick is", imo_number);
    setEditedValues((prevEditedValues) => {
      const newTempValue = prevEditedValues[imo_number]?.[field] || '';
      setTempValue(newTempValue);
      setEditing({ imo_number, field });
      return prevEditedValues;
    });
  };


  const handleInputChange = (imo_number, field, value) => {
    console.log("value inside handleInputChange is", value);
    // const formattedValue = value ? value.replace(/^0+/, '') : '';
    setEditedValues((prev) => ({
      ...prev,
      [imo_number]: {
        ...prev[imo_number],
        // [field]: formattedValue,
        [field]: value,
      },
    }));
    console.log("value inside handleInputChange is", value);
    setTempValue(value);
    setHasChanged(true);
  };


  const handleSave = () => {
    const { imo_number } = editing;
    const vessel = vessels.find((v) => v.imo_number === imo_number);
    const values = editedValues[imo_number];

    if (!vessel) {
      console.error(`Vessel with imo_number ${imo_number} not found`);
      return;
    }

    const payload = [{
      imo_number: vessel.imo_number,
      pwind: values.pwind || vessel.pwind,
      pprop: values.pprop || vessel.pprop,
    }];

    console.log('Payload to be dispatched:', payload); // Log the payload for debugging

    dispatch(updatePwindPprop(payload))
      .unwrap()
      .then(() => {
        console.log('Update successful');
        dispatch(fetchVesselData({ requested_page: pageNumber }));
        setEditing({ imo_number: null, field: null });
      })
      .catch((error) => {
        console.error('Save failed:', error);
      });
    setTimeout(() => {
      setHasChanged(false);
    }, 1000);
  };

  const columns = [
    {
      title: 'IMO Number',
      dataIndex: 'imo_number',
      key: 'imo_number',
      fixed: 'left',
      width: 120,
      sorter: (a, b) => a.imo_number - b.imo_number,
      render: (text) => text || '...',
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      fixed: 'left',
      render: (text) => text || '...',
    },
    {
      title: '',
      dataIndex: 'separator',
      key: 'separator',
      width: 10,
      render: () => <div className="separator-column"></div>,
      fixed: 'left',
    },
    {
      title: 'P-wind',
      dataIndex: 'pwind',
      key: 'pwind',
      sorter: (a, b) => {
        const pwindA = parseFloat(a.pwind) || 0;
        const pwindB = parseFloat(b.pwind) || 0;
        return pwindA - pwindB;
      },
      render: (text, record) => {
        const isEditable = editing.imo_number === record.imo_number && editing.field === 'pwind';
        let value = isEditable ? tempValue : editedValues[record.imo_number]?.pwind || text;


        return (
          <span className='vessel-prop'>

            {isEditable ? (

              <Input
                value={value}
                onChange={(e) => handleInputChange(record.imo_number, 'pwind', e.target.value)}
              // onBlur={() => setEditing({ imo_number: null, field: null })}
              // onFocus={() => setTempValue('')}
              />

            ) : (

              value

            )}
            <img
              src={EditOutlined2}
              alt="Edit"
              style={{ marginLeft: '45px', cursor: 'pointer', color: 'white', borderRadius: '5px' }}
              onClick={() => handleEditClick(record.imo_number, 'pwind')}
            />
          </span>
        );
      },
    },
    {
      title: 'P-Prop',
      dataIndex: 'pprop',
      key: 'pprop',
      sorter: (a, b) => {
        const ppropA = parseFloat(a.pprop) || 0;
        const ppropB = parseFloat(b.pprop) || 0;
        return ppropA - ppropB;
      },
      render: (text, record) => {
        const isEditable = editing.imo_number === record.imo_number && editing.field === 'pprop';
        const value = isEditable ? tempValue : editedValues[record.imo_number]?.pprop || text;
        return (
          <span className='vessel-prop'>
            {isEditable ? (
              <Input
                value={value}
                onChange={(e) => handleInputChange(record.imo_number, 'pprop', e.target.value)}
                onBlur={() => setEditing({ imo_number: null, field: null })}
                onFocus={() => setTempValue('')}
              />
            ) : (
              value
            )}
            <img
              src={EditOutlined2}
              alt="Edit"
              style={{ cursor: 'pointer', color: 'white', borderRadius: '5px', marginLeft: '45px' }}
              onClick={() => handleEditClick(record.imo_number, 'pprop')}
            />
          </span>
        );
      },
    },
    {
      title: 'Reg.Owner',
      dataIndex: 'registered_owner',
      key: 'registered_owner',
      render: (text) => text || '...',
    },
    {
      title: 'Active Owner',
      dataIndex: 'active_owner',
      key: 'active_owner',
      render: (text) => text || '...',
    },
    {
      title: 'Type',
      dataIndex: 'vessel_type',
      key: 'vessel_type',
      render: (text) => text || '...',
    },
    {
      title: 'Sub Type',
      dataIndex: 'address',
      key: 'address',
      render: (text) => text || '...',
    },
    {
      title: 'Build Date',
      dataIndex: 'build_date',
      key: 'build_date',
      sorter: (a, b) => {
        const dateA = new Date(a.build_date);
        const dateB = new Date(b.build_date);
        return dateA - dateB;
      },
      render: (text) => formatDate(text),
    },
    {
      title: 'Ownership Date',
      dataIndex: 'ownership_date',
      key: 'ownership_date',
      sorter: (a, b) => {
        const dateA = new Date(a.ownership_date);
        const dateB = new Date(b.ownership_date);
        return dateA - dateB;
      },
      render: (text) => formatDate(text),
    },
    {
      title: 'Reg.Port',
      dataIndex: 'registration_port',
      key: 'registration_port',
      render: (text) => text || '...',
    },
    {
      title: 'Deadweight',
      dataIndex: 'summer_dead_weight',
      key: 'summer_dead_weight',
      sorter: (a, b) => a.summer_dead_weight - b.summer_dead_weight,
      render: (text) => text || '...',
      width: 152,
    },
    {
      title: 'Gross Tonnage',
      dataIndex: 'gross_ton',
      key: 'gross_ton',
      sorter: (a, b) => a.gross_ton - b.gross_ton,
      render: (text) => text || '...',
    },
    {
      title: 'Net Tonnage',
      dataIndex: 'net_ton',
      key: 'net_ton',
      sorter: (a, b) => a.net_ton - b.net_ton,
      render: (text) => text || '...',
    },
    {
      title: 'Service Speed (NM/Hr)',
      dataIndex: 'service_speed',
      key: 'service_speed',
      render: (text) => text || '...',
    },
    {
      title: 'Manager DocHolder',
      dataIndex: 'managers_doc_holder',
      key: 'managers_doc_holder',
      render: (text) => text || '...',
      width: 145,

    },
    {
      title: 'Technical Group',
      dataIndex: 'technical_group',
      key: 'technical_group',
      render: (text) => text || '...',
    },
    {
      title: 'Type of Carrier',
      dataIndex: 'type_of_carrier',
      key: 'type_of_carrier',
      render: (text) => text || '...',
    },
    {
      title: 'Technical Manager',
      dataIndex: 'technical_managers',
      key: 'technical_managers',
      render: (text) => text || '...',
    },
    {
      title: 'Size of Vessel',
      dataIndex: 'size_of_vessels',
      key: 'size_of_vessels',
      render: (text) => text || '...',
    },
    // {
    //   title: 'Previous Name',
    //   dataIndex: 'previous_name',
    //   key: 'previous_name',
    //   render: (text) => text || '...',
    // },
    {
      title: 'Flag',
      dataIndex: 'flag',
      key: 'flag',
      render: (text) => text || '...',
    },
    {
      title: 'Engine Maker',
      dataIndex: 'engine_maker',
      key: 'engine_maker',
      render: (text) => text || '...',
    },
    {
      title: 'Engine Type',
      dataIndex: 'engine_type',
      key: 'engine_type',
      render: (text) => text || '...',
    },
    {
      title: 'Engine Power (KW)',
      dataIndex: 'engine_power',
      key: 'engine_power',
      render: (text) => text || '...',
    },
    {
      title: 'YTD GHG INTENSITY',
      dataIndex: 'ghg_intensity',
      key: 'ghg_intensity',
      render: (text) => text || '...',
    },
    {
      title: 'YTD COMPLIANCE BALANCE',
      dataIndex: 'cb',
      key: 'cb',
      render: (text) => text || '...',
    },
    {
      title: 'YTD PENALTY',
      dataIndex: 'penalty',
      key: 'penalty',
      render: (text) => text || '...',
    },
    {
      title: 'YTD EUA',
      dataIndex: 'eua',
      key: 'eua',
      render: (text) => text || '...',
    },
    // {
    //   title: 'Engine Fuel Type',
    //   dataIndex: 'engine_fuel_type',
    //   key: 'engine_fuel_type',
    //   render: (text) => text || '...',
    // },
    // {
    //   title: 'Capacity',
    //   dataIndex: 'capacity',
    //   key: 'capacity',
    //   render: (text) => text || '...',
    // },
    // {
    //   title: 'Additional Info',
    //   dataIndex: 'additional_info',
    //   key: 'additional_info',
    //   render: (text) => text || '...',
    // },
  ];

  return (
    <div className="custom-table-container">
      <Table
        columns={columns}
        dataSource={vessels.length > 0 ? vessels : []}
        loading={fetchVesselDataStatus === 'loading'}
        scroll={{ x: 4000, y: 340 }}
        pagination={{
          total: total_pages * 10, // Assuming each page has 10 items
          pageSize: 10,
          showSizeChanger: false,
          onChange: handleTableChange,
          current: pageNumber, // Set the current page number
        }}
        className="custom-table"
        rowKey="imo_number" // Ensure rowKey is set correctly
      />
      {hasChanged && Object.keys(editedValues).length > 0 && (
        <div className="save-button-container">
          <button className="save-button" onClick={handleSave} disabled={updatePwindPpropStatus === 'loading'}>
            {updatePwindPpropStatus === 'loading' ? 'Saving...' : 'Save'}
          </button>
        </div>
      )}

    </div>

  );
};

export default VesselTable;
