import LoginPage from "./pages/loginpage/LoginPage";
import SignPage from "./pages/signuppage/SignupPage";
import "./css/index.css"
import "./App.css"
import { Routes, Route } from "react-router";
import Dashboard from "./pages/dashboard/Dashboard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { restoreLogin } from "./features/Login/UserSlice";

function App() {

  const dispatch = useDispatch()
  const { login, user } = useSelector((state) => state.user)

  useEffect(()=>{
    dispatch(restoreLogin())
  },[])
  return (
    <div className="App">
      <Routes>
        <Route path='/*' element={login && user!==null ? <Dashboard /> : <LoginPage/>} />
        <Route path='/signup' element={<SignPage />} />
      </Routes>
    </div>
  );
}

export default App;
