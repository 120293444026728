import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Input, Select, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeftOutlined, SettingOutlined } from '@ant-design/icons';
import { patchBlandedFuelInfo, postBlandedFuelInfo } from '../../../features/RawData/RawDataSlice';
import ConflictingDataModal from './ConflictingDataModal';

const { Option } = Select;



let blendFuelData = {
    data:
    {
        fuel_consumption_id: null,
        fuel_name: "",
        class_name: "",
        user_remark: "",
        consumption: null,
        rob: null,
        bunker_survey: '',
        bunker_quantity: '',
        blend_fuel: [
            {
                id: 1,
                blend_name: '',
                cslip: '',
                rwdi: '',
                lcv: '',
                ef_ttw_MJfuel: '',
                ef_ttw_gfuel: '',
                ef_wtt_MJfuel: '',
                ef_wtt_gfuel: '',
                class_name: 'Fossil',
                blend_percentage: ''
            },
            {
                id: 2,
                blend_name: '',
                cslip: '',
                rwdi: '',
                lcv: '',
                ef_ttw_MJfuel: '',
                ef_ttw_gfuel: '',
                ef_wtt_MJfuel: '',
                ef_wtt_gfuel: '',
                class_name: '',
                blend_percentage: ''
            }
        ]
    }
}


const BioFuelEditModal = ({ visible, setIsModalVisible, selectedUniqueId, selectedCards, bioFuelData }) => {
    // console.log(bioFuelData)
    const fuelDataByUniqueId = useSelector((state) => state.rawData.fuelDataByUniqueId);
    const { blendedFuelsGetData } = useSelector((state) => state.rawData);
    console.log(blendedFuelsGetData)
    console.log(bioFuelData)

    const dispatch = useDispatch();

    const [isTableEdited, setIsTableEdited] = useState(false)
    const [editingData, setEditingData] = useState([]);

    const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);

    useEffect(() => {
        if (blendFuelData.data.blend_fuel) {
            setEditingData(blendFuelData.data.blend_fuel);
        }

    }, [blendFuelData, visible]);

    useEffect(() => {

        console.log(blendedFuelsGetData?.fuel_metadata_id)
        blendFuelData = {
            data: {
                fuel_consumption_id: bioFuelData?.id,
                fuel_name: bioFuelData?.fuel_name,
                class_name: bioFuelData?.class_name,
                user_remark: "",
                consumption: bioFuelData?.consumption,
                rob: bioFuelData?.rob,
                bunker_survey: bioFuelData?.bunker_survey,
                bunker_quantity: bioFuelData?.bunker_quantity,
                blend_fuel: blendedFuelsGetData?.data?.length > 0 && bioFuelData?.fuel_metadata_id == blendedFuelsGetData?.fuel_metadata_id
                    ? blendedFuelsGetData?.data?.map((fuel, index) => ({
                        id: index + 1,
                        blend_id: fuel?.blend_id || null,
                        blend_name: fuel?.fuel_name || '',
                        cslip: '',
                        rwdi: '',
                        lcv: fuel?.lcv || '',
                        ef_ttw_MJfuel: fuel?.ef_ttw_MJfuel || '',
                        ef_ttw_gfuel: fuel?.ef_ttw_gfuel || '',
                        ef_wtt_MJfuel: fuel?.ef_wtt_MJfuel || '',
                        ef_wtt_gfuel: fuel?.ef_wtt_gfuel || '',
                        class_name: fuel?.class_name || 'hh',
                        blend_percentage: ''
                    }))
                    : [
                        {
                            id: 1,
                            blend_name: '',
                            cslip: '',
                            rwdi: '',
                            lcv: '',
                            ef_ttw_MJfuel: '',
                            ef_ttw_gfuel: '',
                            ef_wtt_MJfuel: '',
                            ef_wtt_gfuel: '',
                            class_name: 'Fossil',
                            blend_percentage: ''
                        },
                        {
                            id: 2,
                            blend_name: '',
                            cslip: '',
                            rwdi: '',
                            lcv: '',
                            ef_ttw_MJfuel: '',
                            ef_ttw_gfuel: '',
                            ef_wtt_MJfuel: '',
                            ef_wtt_gfuel: '',
                            class_name: '',
                            blend_percentage: ''
                        }
                    ]
            }
        };

        console.log(bioFuelData?.fuel_metadata_id == blendedFuelsGetData?.fuel_metadata_id)
    }, [blendedFuelsGetData, bioFuelData]);


    // useEffect(() => {
    //     const checkAllFieldsFilled = editingData.every((item) =>
    //         Object.values(item).every(value => value !== '')
    //     );
    //     setIsAllFieldsFilled(checkAllFieldsFilled);
    // }, [editingData]);

    const handleInputChange = (e, record, key) => {
        const newData = [...editingData];
        const index = newData.findIndex((item) => record.id === item.id);
        const item = newData[index];
        newData[index] = {
            ...item,
            [key]: e.target.value,
            modified: {
                ...item.modified,
                [key]: item[key] !== e.target.value
            }
        };
        setEditingData(newData);

        // Check if all fields are filled in blend_fuel
        const allFieldsFilled = newData.every((item) => {
            return Object.values(item).every(value => value !== '' && value !== null && value !== undefined);
        });

        // Compare newData with the original blendFuelData to see if any changes were made
        const hasChanged = !blendFuelData.data.blend_fuel.every((fuelItem, idx) => {
            return Object.keys(fuelItem).every(key => fuelItem[key] === newData[idx][key]);
        });

        // Enable the submit button only if all fields are filled and changes were made
        setIsTableEdited(hasChanged && allFieldsFilled);
    };

    const handleSelectChange = (value, record, key) => {
        const newData = [...editingData];
        const index = newData.findIndex((item) => record.id === item.id);
        const item = newData[index];
        newData[index] = {
            ...item,
            [key]: value,
            modified: {
                ...item.modified,
                [key]: item[key] !== value
            }
        };
        setEditingData(newData);

        // Check if all fields are filled in blend_fuel
        const allFieldsFilled = newData.every((item) => {
            return Object.values(item).every(value => value !== '' && value !== null && value !== undefined);
        });

        // Compare newData with the original blendFuelData to see if any changes were made
        const hasChanged = !blendFuelData.data.blend_fuel.every((fuelItem, idx) => {
            return Object.keys(fuelItem).every(key => fuelItem[key] === newData[idx][key]);
        });

        // Enable the submit button only if all fields are filled and changes were made
        setIsTableEdited(hasChanged && allFieldsFilled);
    };

    const handleClose = () => {
        setEditingData([]);
        setIsTableEdited(false);
        setIsModalVisible(false);
    };

    const handleSubmit = () => {
        // console.log("Edited Data:", editingData);
        // You can add further processing here, like making an API call with editedData

        const formattedData = editingData.map(item => {
            const { class_name, blend_name, modified, ...rest } = item;
            const floatFields = Object.keys(rest).reduce((acc, key) => {
                acc[key] = parseFloat(rest[key]);
                return acc;
            }, {});

            return {
                ...floatFields,
                class_name,
                blend_name,
                modified
            };
        });

        // console.log("Formatted Data:", formattedData);

        const payload = {
            data:
            {
                fuel_consumption_id: bioFuelData?.id,
                fuel_name: bioFuelData?.fuel_name,
                class_name: bioFuelData?.class_name,
                user_remark: '',
                consumption: bioFuelData?.consumption,
                rob: bioFuelData?.rob,
                bunker_survey: bioFuelData?.bunker_survey,
                bunker_quantity: bioFuelData?.bunker_quantity,
                blend_fuel: formattedData,
                unique_id: selectedUniqueId,
                fuel_metadata_id: bioFuelData?.fuel_metadata_id
            }
        }

        if (blendedFuelsGetData?.data?.length > 0 && bioFuelData?.fuel_metadata_id == blendedFuelsGetData?.fuel_metadata_id) {
            dispatch(patchBlandedFuelInfo(payload))
        } else {
            dispatch(postBlandedFuelInfo(payload))
        }

        handleClose()
    };

    const columns = [
        {
            title: 'Fuel name',
            dataIndex: 'blend_name',
            key: 'blend_name',
            width: 150,
            render: (text, record) => {
                // console.log('record.modified:', record.modified);
                // console.log('record', record);
                return (
                    <Input className={record.modified?.blend_name ? 'fuel-class-custom-select custom-cell modified-cell' : 'custom-cell'}
                        value={text}
                        onChange={(e) => handleInputChange(e, record, 'blend_name')} />
                );
            }

        },
        {
            title: 'Fuel Class',
            dataIndex: 'class_name',
            key: 'class_name',
            render: (text, record) => {
                // console.log('record.modified:', record.modified);
                return (
                    <Select className={record.modified?.class_name ? 'fuel-class-custom-select modified-cell' : 'fuel-class-custom-select'}
                        value={text} onChange={(value) => handleSelectChange(value, record, 'class_name')}>
                        <Option value="Fossil">Fossil</Option>
                        <Option value="RFNBO">RFNBO</Option>
                        <Option value="Biofuel">Biofuel</Option>
                    </Select>
                )
            }
        },
        {
            title: 'Percentage',
            dataIndex: 'blend_percentage',
            key: 'blend_percentage',
            render: (text, record) => <Input className={record.modified?.blend_percentage ? 'modified-cell' : ''} value={text} onChange={(e) => handleInputChange(e, record, 'blend_percentage')} />
        },
        {
            title: 'LCV(MJ/MT)*',
            dataIndex: 'lcv',
            key: 'lcv',
            render: (text, record) => <Input className={record.modified?.lcv ? 'modified-cell' : ''} value={text} onChange={(e) => handleInputChange(e, record, 'lcv')} />
        },
        {
            title: 'Cslip*',
            dataIndex: 'cslip',
            key: 'cslip',
            render: (text, record) => <Input className={record.modified?.cslip ? 'modified-cell' : ''} value={text} onChange={(e) => handleInputChange(e, record, 'cslip')} />
        },
        {
            title: 'EF-TTW (MJfuel)*',
            dataIndex: 'ef_ttw_MJfuel',
            key: 'ef_ttw_MJfuel',
            render: (text, record) => <Input className={record.modified?.ef_ttw_MJfuel ? 'modified-cell' : ''} value={text} onChange={(e) => handleInputChange(e, record, 'ef_ttw_MJfuel')} />
        },
        {
            title: 'EF-TTW (Gfuel)*',
            dataIndex: 'ef_ttw_gfuel',
            key: 'ef_ttw_gfuel',
            render: (text, record) => <Input className={record.modified?.ef_ttw_gfuel ? 'modified-cell' : ''} value={text} onChange={(e) => handleInputChange(e, record, 'ef_ttw_gfuel')} />
        },
        {
            title: 'EF-WTT (MJfuel)*',
            dataIndex: 'ef_wtt_MJfuel',
            key: 'ef_wtt_MJfuel',
            render: (text, record) => <Input className={record.modified?.ef_wtt_MJfuel ? 'modified-cell' : ''} value={text} onChange={(e) => handleInputChange(e, record, 'ef_wtt_MJfuel')} />
        },
        {
            title: 'EF-WTT (Gfuel)*',
            dataIndex: 'ef_wtt_gfuel',
            key: 'ef_wtt_gfuel',
            render: (text, record) => <Input className={record.modified?.ef_wtt_gfuel ? 'modified-cell' : ''} value={text} onChange={(e) => handleInputChange(e, record, 'ef_wtt_gfuel')} />
        },
        {
            title: 'RWDi*',
            dataIndex: 'rwdi',
            key: 'rwdi',
            render: (text, record) => <Input className={record.modified?.rwdi ? 'modified-cell' : ''} value={text} onChange={(e) => handleInputChange(e, record, 'rwdi')} />
        }
    ];

    return (
        <Modal
            title="Biofuel Edit"
            visible={visible}
            onCancel={handleClose}
            footer={[
                <div className='fuel-edit-modal-footer'>
                    <div className='fuel-edit-modal-footer-remark'>
                    </div>
                    <div className='fuel-edit-modal-footer-buttons'>
                        <Button key="back" onClick={handleClose} icon={<ArrowLeftOutlined />} className='cancel-button-stepper'>
                            Close
                        </Button>
                        <Button key="submit" type="primary" onClick={handleSubmit} className='stepper-next-button' disabled={!isTableEdited}>
                            Submit
                        </Button>
                    </div>
                </div>
            ]}
            className='fuel-edit-modal'
            style={{ top: 50 }}
        >
            <Table
                dataSource={editingData}
                columns={columns}
                rowKey="id"
                pagination={false}
                scroll={{ x: true }}
                className='fuel-data-stepper-table'
                bordered
            />
        </Modal>
    );
};

export default BioFuelEditModal;
