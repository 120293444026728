import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";
import { hostname } from "../../data";


export const fetchCasperRecords = createAsyncThunk(
    'casperAnalysis/fetchCasperRecords',
    async (params) =>{
        
        const param = {
            "casper_id": params.casperID,
            "imo_number": params.imoNumber,
            "year": params.year,
        }
    const request = await makeRequest("get", `${hostname}api/ems/voyages/get_by_casper_id/?imo_number=${param.imo_number}&year=${param.year}&casper_id=${param.casper_id}`)
    const response = await request.data.data
   
    return response
    }
  ); 

  export const AnnualVesselAnalysis = createAsyncThunk(
    'casperAnalysis/AnnualVesselAnalysis',
    async (params) =>{
        const param = {
            "imo_number": params.iMONumber,
            "year": params.voyageYear,
        }
    const request = await makeRequest("get", `${hostname}api/ems/annual_vessel_analysis/?imo_number=${param.imo_number}&year=${param.year}`)
    const response = await request.data.data
   
    return response
    }
  );  

 

const CasperAnalysisSlice = createSlice({
  name: 'casperAnalysis',
  initialState: {
    fetchCasperRecordsStatus: 'idle',
    casperRecords: [],
    // page: 1,
    // total_pages: 0,
    fetchCasperRecordError: null,
    fetchvesselAnanlysisDataStatus:'idle',
    vesselAnalysisData:{},
   
  },
  reducers: {
    // clearCaspers: (state) => {
    //   state.page = 1;
    //   state.vessels = [];
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCasperRecords.pending, (state) => {
        state.fetchCasperRecordsStatus = 'loading';
      })
      .addCase(fetchCasperRecords.fulfilled, (state, action) => {
        state.fetchCasperRecordsStatus = 'succeeded';
        state.casperRecords = action.payload; // Replace data instead of appending
        // state.page = action.payload.page;
        // state.total_pages = action.payload.total_pages;
      })
      .addCase(fetchCasperRecords.rejected, (state, action) => {
        state.fetchCasperRecordsStatus = 'failed';
        state.fetchCasperRecordError = action.payload;
      })
      .addCase(AnnualVesselAnalysis.pending, (state) => {
        state.fetchvesselAnanlysisDataStatus = 'loading';
        state.vesselAnalysisData ={}
      })
      .addCase(AnnualVesselAnalysis.fulfilled, (state, action) => {
        state.fetchvesselAnanlysisDataStatus = 'succeeded';
        state.vesselAnalysisData = action.payload;
      })
      .addCase(AnnualVesselAnalysis.rejected, (state) => {
        state.fetchvesselAnanlysisDataStatus = 'failed';
        state.vesselAnalysisData ={}
      })

  },
});


// export const { clearCaspers } = CasperAnalysisSlice.actions;

export default CasperAnalysisSlice.reducer;
