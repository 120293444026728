import React, { useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import VesselTable from './vesselTable';
import vesselsvg from '../../assets/icons/rawdata/tabler_ship.svg';
import CertificationModal from '../../components/Modals/CertificationModal';

const VesselPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleNewRecordClick = () => {
    navigate('/create-vessel');
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{marginTop:"6px"}} className="vessel-page">
      <div className="header">
        <h3>Vessel Record</h3>
        {/* <Button onClick={showModal}>Casper</Button>
        <button className="new-record-button" onClick={handleNewRecordClick}>
          <span className='icon-placeholder'><img src={vesselsvg} alt="icon" /></span> New Vessel Record
        </button> */}
      </div>
      <hr className='hr'></hr>
      <div className='vessel-table'>
        <VesselTable />
      </div>
      {/* <div className="save-button-container">
        <button className="save-button">Save</button>
      </div> */}
      
      <CertificationModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
      />
    </div>
  );
}

export default VesselPage;
