import React, { useEffect, useState } from 'react';
import { AutoComplete, Row, Col, Typography, Spin, Alert } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchShipOwners,
  fetchVessels,
  fetchDocSuggestions,
  fetchVesselData,
  clearVessels
} from '../../features/VesselsAnalysis/VesselsAnalysisSlice';

const { Title } = Typography;

const VesselFilter = () => {
  const dispatch = useDispatch();
  const {
    shipOwners,
    shipOwnersStatus,
    vesselDetails,
    vesselStatus,
    docSuggestions,
    docSuggestionsStatus
  } = useSelector((state) => state.vesselsAnalysis);

  const [shipOwner, setShipOwner] = useState('');
  const [vesselName, setVesselName] = useState('');
  const [iMONumber, setIMONumber] = useState('');
  const [doc, setDoc] = useState('');

  // Fetch DOC suggestions and ship owners initially
  useEffect(() => {
    dispatch(fetchDocSuggestions());
    // Fetch ship owners without doc initially
  }, [dispatch]);

  // Fetch ship owners when doc changes
  useEffect(() => {
    if (doc) {
      dispatch(fetchShipOwners(doc)); // Fetch ship owners with doc
    } else {
      dispatch(fetchShipOwners('')); // Fetch ship owners without doc
    }
  }, [dispatch, doc]);

  // Update vessel details based on selected ship owner
  // useEffect(() => {
  //   if (shipOwnersStatus === 'succeeded' && shipOwners.length > 0) {
  //     const defaultShipOwner = shipOwners[0];
  //     setShipOwner(defaultShipOwner);
  //     dispatch(fetchVessels(defaultShipOwner));
  //   }
  // }, [dispatch, shipOwnersStatus, shipOwners]);

  // // Update vesselName and iMONumber based on vessel details
  // useEffect(() => {
  //   if (vesselStatus === 'succeeded' && vesselDetails.length > 0) {
  //     setVesselName(vesselDetails[0]?.vessel_name || '');
  //     setIMONumber(vesselDetails[0]?.imo_number || '');
  //   }
  // }, [vesselDetails, vesselStatus]);

  // Handle changes for ship owner, vessel name, IMO number, and DOC
  const handleShipOwnerChange = (value) => {
    setShipOwner(value);
    dispatch(fetchVessels(value));
  };

  const handleVesselChange = (value) => {
    setVesselName(value);
    const selectedVessel = vesselDetails.find((vessel) => vessel.vessel_name === value);
    if (selectedVessel) {
      setIMONumber(selectedVessel.imo_number);

    }
  };

  const handleIMONumberChange = (value) => {
    setIMONumber(value);

  };

  const handleDocChange = (value) => {
    setDoc(value);
  };

  const resetFilters = () => {
    setShipOwner('');
    setVesselName('');
    setIMONumber('');
    setDoc('');
    dispatch(clearVessels());
    dispatch(fetchDocSuggestions());
    dispatch(fetchShipOwners('')); // Fetch ship owners without doc
    dispatch(fetchVesselData({ page: 1 }));
  };

  const handleApply = () => {
    dispatch(fetchVesselData({
      page: 1,
      shipowner_name: shipOwner,
      doc,
      imo_number: iMONumber,
      vessel_name: vesselName
    }));
  };

  return (
    <div className="vessel-filter-container">
      <div className="filter-title">Filters</div>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <div className="filter-item">
            <Title level={5} className="filter-heading">DOC</Title>
            {docSuggestionsStatus === 'loading' ? (
              <Spin />
            ) : docSuggestionsStatus === 'failed' ? (
              <Alert
                message="Error"
                description="Failed to load DOC suggestions."
                type="error"
                showIcon
              />
            ) : (
              <AutoComplete
                placeholder="Select or enter DOC"
                className="filter-select"
                allowClear
                value={doc}
                onChange={handleDocChange}
                options={docSuggestions.map((doc) => ({ value: doc }))}
              />
            )}
          </div>
        </Col>
        <Col span={6}>
          <div className="filter-item">
            <Title level={5} className="filter-heading">Ship Owner</Title>
            {shipOwnersStatus === 'loading' ? (
              <Spin />
            ) : shipOwnersStatus === 'failed' ? (
              <Alert
                message="Error"
                description="Failed to load ship owners."
                type="error"
                showIcon
              />
            ) : (
              <AutoComplete
                placeholder="Select or enter Ship Owner"
                className="filter-select"
                allowClear
                value={shipOwner}
                onChange={handleShipOwnerChange}
                options={shipOwners.map((owner) => ({ value: owner }))}
              />
            )}
          </div>
        </Col>
        <Col span={6}>
          <div className="filter-item">
            <Title level={5} className="filter-heading">Vessel Name</Title>
            {vesselStatus === 'loading' ? (
              <Spin />
            ) : vesselStatus === 'failed' ? (
              <Alert
                message="Error"
                description="Failed to load vessels."
                type="error"
                showIcon
              />
            ) : (
              <AutoComplete
                placeholder="Select or enter Vessel Name"
                className="filter-select"
                allowClear
                value={vesselName}
                onChange={handleVesselChange}
                options={vesselDetails.map((vessel) => ({ value: vessel?.vessel_name }))}
              />
            )}
          </div>
        </Col>
        <Col span={6}>
          <div className="filter-item">
            <Title level={5} className="filter-heading">IMO Number</Title>
            {vesselStatus === 'loading' ? (
              <Spin />
            ) : vesselStatus === 'failed' ? (
              <Alert
                message="Error"
                description="Failed to load IMO numbers."
                type="error"
                showIcon
              />
            ) : (
              <AutoComplete
                placeholder="Select or enter IMO Number"
                className="filter-select"
                allowClear
                value={iMONumber}
                onChange={handleIMONumberChange}
                options={vesselDetails.map((vessel) => ({ value: vessel?.imo_number }))}
              />
            )}
          </div>
        </Col>
      </Row>
      <div className="buttons-container">
        <div style={{ fontSize: "1.8vh", opacity: "0.7", fontStyle: "italic !important" }}></div>
        <div style={{ display: "flex", gap: "1vw", marginTop: "2vh" }}>
          <div className="reset-btn" onClick={resetFilters}>
            Reset
          </div>
          <div className="apply-btn" onClick={handleApply}>
            Apply
          </div>
        </div>
      </div>
    </div>
  );
};

export default VesselFilter;
