import React, { useEffect } from 'react'
import DataRecordTab from './DataRecordsTab/DataRecordTab'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCasperRecords } from '../features/CasperAnalysis/CasperAnalysisSlice'

const DataRecords = ({ setModal, setSelectedCasperIdAuditTrace }) => {
  const dispatch = useDispatch()
  const { fetchCasperRecordsStatus, casperRecords, fetchCasperRecordError } = useSelector((state) => state.casperAnalysis)

  // useEffect(()=>{
  //   if(fetchCasperRecordsStatus === 'idle'){
  //     dispatch(fetchCasperRecords()) 
  //   }
  // },[dispatch, fetchCasperRecordsStatus])

  // if(fetchCasperRecordsStatus === 'idle'){
  //   return <div>No data</div>
  // }

  // useEffect(()=>{
  //   localStorage.removeItem('casperRecords')
  // })

  if (fetchCasperRecordsStatus === 'loading') {
    return <div>Loading...</div>  // to do (add spinner/loader)
  }

  return (
    <div className='data-record'>
      <div className='data-record-header'>
        <div className='data-record-title'>
          <span style={{ fontSize: "20px", fontWeight: "500", color: "414141", lineHeight: "normal" }} >Casper Records </span>
          {fetchCasperRecordsStatus === 'succeeded' ? <p style={{ fontSize: "12px", color: "#414141" }} >{casperRecords.length} Records found</p> : <p style={{ fontSize: "12px", color: "#414141" }}>0 Records Found</p>}
        </div>
        <hr />
      </div>
      <div className='data-record-tabs'>


        {/* <DataRecordTab setModal={setModal}/>
        <DataRecordTab setModal={setModal}/> */}

        {fetchCasperRecordsStatus === 'succeeded' && casperRecords.length > 0 &&

          <>
            {casperRecords.map((record, index) => (

              <DataRecordTab key={index} setModal={setModal} record={record} setSelectedCasperIdAuditTrace={setSelectedCasperIdAuditTrace} />

            ))}
          </>


        }



      </div>

    </div>
  )
}

export default DataRecords