import React from 'react'
import { ReactComponent as Time } from "../../assets/icons/rawdata/humbleicons_clock.svg"
const AuditTracesHeader = ({ auditDetails }) => {
    return (
        <div className='audit-traces-header'>
            <div className='row-1'>
                <div className='text'>
                    <p>Audit Traces</p>
                    <div className='records'>
                        {/* <p>132 Records</p>
                        <hr /> */}
                        <p>{auditDetails?.message}</p>
                    </div>
                </div>
                <div className='time'>
                    <div className='time-detail'>
                        <Time style={{ width: "2.5vh", height: "2.5vh" }} />
                        <div>{auditDetails?.date_created}</div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#678DFF", color: "white", padding: "0.5vh 0.5vw", borderRadius: "1vw", fontSize: "1.5vh", fontWeight: "100", marginTop: "0.2vh" }}>
                        {auditDetails?.is_system_generated ? "Automatic" : "Manual"}
                    </div>
                </div>
            </div>
            <div className='row-2'>
                <div>
                    User remarks
                </div>
                <p>
                    {auditDetails?.user_remarks}
                </p>
            </div>
        </div>
    )
}

export default AuditTracesHeader